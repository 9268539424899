module.exports = [
	{
		headline: 'WARUM',
		value: 'Neue Fähigkeiten zu erlernen, selbst zu  produzieren und selbst aktiv zu werden zeichnet die Projekte ' +
		'auf dieser Seite aus. ' +
		'Die Mitwirkenden haben Folgendes gemeinsam: Sie suchen konstruktiv nach Lösungen für aktuelle ' +
		'sozialökologische Herausforderungen und bringen sich durch die Umsetzung ihrer Ideen aktiv bei der Gestaltung ' +
		'der Stadt ein. ' +
		'Das trägt nicht nur dazu bei, das Klima zu schützen und Ressourcen zu schonen; es erweitert auch die ' +
		'Möglichkeiten der kostenlosen Freizeitgestaltung und bereichert den Stadtraum, indem alternative Ideen zu ' +
		'seiner Nutzung entwickelt werden. ' +
		'Es gibt wissenschaftliche Hinweise darauf, dass solche Projekte und das Selbst-aktiv-werden nicht nur die ' +
		'eigene Lebensqualität verbessern; sie fördern auch die urbane Gemeinschaft und tragen zu globaler ' +
		'Gerechtigkeit und Generationengerechtigkeit bei. ' +
		'Dabei ist Würzburg nicht alleine. ' +
		'Die Transition-Town-Bewegung und viele dazugehörige Projekte gibt es bereits in über 40 Ländern. ' +
		'Über die weiterführenden Links gibt es Hintergrundinformationen.'
	}
];