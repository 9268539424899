var React = require('react'),

	data = require('../data/data').info(),

	Page = require('./page/page.jsx'),
	ContentText = require('./onpage/content/contentText.jsx');



var Info = React.createClass({

	render: function(){

		return(
			<Page
				header={data.header}
				footer={data.footer}
			>

				<div className = " info project-info-container">

					<div className="project-info">
						{data.values.map(function (element) {
							return(
								<ContentText
									headline={element.headline}
									key={element.headline}
								>
									{element.value}
								</ContentText>
							)
						})}
					</div>

					<div className="project-add-info-outer">
						<div className="infobox why">
							<div className="bold why">WEITERFÜHRENDE INFOS</div>
							<div className="normal">
								<a href="http://www.postwachstum.de" target="_blank">www.postwachstum.de</a>
							</div>
							<div className="normal">
								<a href="http://www.transitionnetwork.org" target="_blank">www.transitionnetwork.org</a>
							</div>
						</div>
					</div>
				</div>

			</Page>
		)
	}

});

module.exports = Info;


