var React = require('react'),

	data = require('../data/data').landingPage(),

	Link = require('react-router').Link,

	Page = require('./page/page.jsx'),
	Headline = require('./onpage/headline/headline.jsx'),
	Filter = require('./onpage/headline/filter.jsx'),
	Slider = require('./onpage/slider/slider.jsx'),
	ProjectTeaser = require('./onpage/projectTeaser/projectTeaser.jsx'),
	ContentText = require('./onpage/content/contentText.jsx'),
	ContentBig = require('./onpage/content/contentBig.jsx'),
	ContentParallel = require('./onpage/content/contentParallel.jsx'),
	SocialFacebook = require('./onpage/social/facebook.jsx');

// TODO maybe implement slider as <Link> instead of javascript click?



var LandingPage = React.createClass({

	handleClick: function(dir) {
		var projectId = this.state.projectId;
		dir === 'left' ? this.previous(projectId) : this.next(projectId);

		
	},

	next: function (current) {
		var projectId = current < data.slider.length-1 ? ++current : 0;

		clearInterval(this.timer);
		this.timer = setInterval(this.displayNextProject, 6000);

		this.setState({projectId: projectId});
	},

	previous: function (current) {
		var projectId = current > 0 ? --current : data.slider.length-1;

		clearInterval(this.timer);
		this.timer = setInterval(this.displayNextProject, 6000);

		this.setState({projectId: projectId});
	},

	displayNextProject: function () {
		this.next(this.state.projectId);
	},

	displayRandomProject: function () {
		var rand = Math.floor(Math.random() * data.slider.length);
		this.setState({projectId: rand});
	},



	getInitialState: function () {
		return {
			projectId: 0,
			menuCollapsed: true,
			isIntroPlaying: false
		};
	},

	componentDidMount: function () {

		if(!this.props.location.query.onsite) {

			this.setState({isIntroPlaying: true});

			setTimeout((function () {
				this.setState({isIntroPlaying: false});
				this.displayRandomProject();
				this.timer = setInterval(this.displayNextProject, 6000);
			}).bind(this), 3500);
		}




		/*
		window.addEventListener('scroll', (function (evt) {

			console.log(isScrolledIntoView(this.refs['firstContentArea']));


			function isScrolledIntoView(el) {
				var elemTop = el.getBoundingClientRect().top;
				var elemBottom = el.getBoundingClientRect().bottom;

				var isVisible = (elemTop >= 0) && (elemBottom <= window.innerHeight);
				return isVisible;
			}
		}).bind(this));
		*/

	},

	componentWillUnmount: function () {
		clearInterval(this.timer);
	},



	render: function(){

		return(

			<div>

			{this.state.isIntroPlaying ?

				<div className="intro-animation" style={{backgroundImage: 'url(../assets/img/intro/gifwebsite.gif?' + Math.random() + ')'}}></div>

				:


				<Page
					header={data.header}
					footer={data.footer}
				>


					<Headline
						title={data.headline.title}
						fading={true}
					>
						<Link to="/projects">Alle Projekte</Link>
					</Headline>

					<Slider
						onClickLeft={this.handleClick.bind(this, 'left')}
						onClickRight={this.handleClick.bind(this, 'right')}>
						<ProjectTeaser
							teaserImgSrc={data.slider[this.state.projectId].teaserImgSrc}
							projectName={data.slider[this.state.projectId].projectName}
							projectDescription={data.slider[this.state.projectId].projectDescription}
							linkTo={data.slider[this.state.projectId].linkTo}>
						</ProjectTeaser>
					</Slider>


					{data.content.map(function (content) {

						if (content.type === 'contentBig') {
							return (
								<ContentBig key={content.value}>
									{content.value.map(function (c) {
										if (c.type === 'text') {
											return c.value;
										}
										if (c.type === 'link') {
											return <Link key={c.value} to={c.to}>{c.value}</Link>
										}
									})}
								</ContentBig>
							)
						}

						if (content.type === 'contentText') {
							return (
								<ContentText ref="firstContentArea"
												key={content.value}
											 	headline={content.value.headline}>
									{content.value.value}
								</ContentText>
							)
						}

						if (content.type === 'contentParallel') {
							return (
								<ContentParallel key={content.value.left.headline.value}>
									<ContentText
										headline={<Link to={content.value.left.headline.to}>{content.value.left.headline.value}</Link>}>
										{content.value.left.value}
									</ContentText>
									<ContentText
										headline={<Link to={content.value.right.headline.to}>{content.value.right.headline.value}</Link>}>
										{content.value.right.value}
									</ContentText>
								</ContentParallel>
							)
						}
					})}


					<SocialFacebook
						fLink={data.social.imgSrc}
						to={data.social.to}
					>
						{data.social.value}
					</SocialFacebook>


				</Page>

			}

			</div>
		)
	}

});

module.exports = LandingPage;


