var React = require('react'),
	Link = require('react-router').Link;

var ProjectTeaser = React.createClass({

	propTypes: {
		teaserImgSrc: React.PropTypes.string.isRequired,
		projectName: React.PropTypes.string.isRequired,
		projectDescription: React.PropTypes.string.isRequired,
		linkTo: React.PropTypes.string,
		disableLink: React.PropTypes.bool,
		showAll: React.PropTypes.bool,
		showSubheadline: React.PropTypes.bool
	},

	getDefaultProps: function() {
		return {
			showAll: true,
			showSubheadline: true
		};
	},

	render: function(){

		var headlineClass = 'project-name';
		headlineClass += this.props.showSubheadline === false ? ' bottom' : '';

		return(
			<div className="project-teaser">

				<Link to={this.props.linkTo} className={this.props.disableLink ? 'link inactive': 'link'}>

					<img src={this.props.teaserImgSrc} className="teaser-img" />
					{
						(function () {
							if(this.props.showAll) {
								return(
									<div className="hover-outer">
										<div className="teaser-img-hover"></div>
										<div className={headlineClass}>{this.props.projectName}</div>
										{(function () {
											if(this.props.showSubheadline === true){
												return(
													<div className="project-description">{this.props.projectDescription}</div>
												)
											}
										}).bind(this)()}
									</div>

								)
							}
						}).bind(this)()
					}
				</Link>
			</div>
		)
	}

});

module.exports = ProjectTeaser;