/**
 * This file contains a full description of all project attributes.
 * Each project references the descriptions by id.
 */

module.exports = {

	0: {
		0: 'Kommunikation/Vernetzung',
		1: 'Organisation',
		2: 'Handwerkliches/Reparatur',
		3: 'Soziales',
		4: 'Handarbeit',
		5: 'Kochen/Backen',
		6: 'Fahrrad-/Autofahren',
		7: 'Elektronik/Mechanik',
		8: 'Informatik',
		9: 'Gartenarbeit',
		10: 'Bildung/Workshop'
	},
	
	1: {
		0: 'Fair & Sozial',
		1: 'Nutzungsdauer verlängern',
		2: 'Verschwendung vermeiden',
		3: 'Recycling/Upcycling'
	},
	
	2: {
		0: 'einmalig',
		1: 'gelegentlich',
		2: 'regelmäßig'
	},
	
	3: {
		0: 'Transportfahrten',
		1: 'Werkzeug',
		2: 'Räumlichkeiten',
		3: 'Material',
		4: 'Lebensmittel',
		5: 'Zeit'
	},
	
	4: {
		0: 'Draußen',
		1: 'Drinnen'

		// 2: 'Beides', /* obviously we dont need this, as we are allowing multiple filters to be applied */
		// 3: 'egal' /* ditto, no filter on in or out == i dont care... */
	}

};
/*,
 11: 'Vernetzung'*/