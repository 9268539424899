module.exports = {

	header: require('./header'),

	teasers: [
		{
			value: 'Sinnvolle Projekte brauchen Menschen die mitmachen.'
		},
		{
			value: 'Die Auswahlkriterien helfen das Richtige zu finden.'
		}
	],

	questions: [
		{
			value: 'WAS MACHE ICH GERNE?',
			targetAttrType: 0
		},
		{
			value: 'WAS IST MIR WICHTIG?',
			targetAttrType: 1
		},
		{
			value: 'WIE OFT HABE ICH ZEIT?',
			targetAttrType: 2
		},
		{
			value: 'WAS KANN ICH EINBRINGEN?',
			targetAttrType: 3
		},
		{
			value: 'DRINNEN ODER DRAUSSEN?',
			targetAttrType: 4
		}
	],

	footer: require('./footer')

};