var React = require('react');

var ContentText = React.createClass({

	render: function () {

		return (
			<div className="content-text">
				<div className="content-box">
					<div className="headline">{this.props.headline}</div>
					{React.Children.map(this.props.children, function (child) {
						return <div className="text">{child}</div>
					})}
				</div>
			</div>
		)
	}

});

module.exports = ContentText;