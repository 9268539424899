var React = require('react'),
	Link = require('react-router').Link;



module.exports = React.createClass({



	/**
	 * The parts above/beneath {this.props.children} are always visible on each route.
	 * To make an entirely new site, define a top-level route in react-routes.jsx.
	 */
	render: function(){
		return(
			<div>
				{this.props.children}
			</div>
		)
	}

});




// <Navbar />
// <Footer copyrightText={data.footer.copyright}></Footer>

/*

 {this.getInitialState()}
 ,

 getInitialState: function () {
 return {
 projectOverview: Dispatcher.getStore(ProjectStore).getAllProjects()
 }
 },

 onChange: function () {
 this.setState(this.getInitialState());
 },
 componentDidMount: function () {
 Dispatcher.getStore(ProjectStore).addChangeListener(this.onChange);
 },
 componentWillUnmount: function () {
 Dispatcher.getStore(ProjectStore).removeChangeListener(this.onChange);
 },


 Navbar = require('./navbar/navbar.jsx'),
 Footer = require('./footer/footer.jsx');
 */