var React = require('react'),
	PropTypes = require('react').PropTypes,
	Link = require('react-router').Link,

	Menu = require('../onpage/menu/menu.jsx');



var Header = React.createClass({


	propTypes: {
		location: PropTypes.string,
		menu: PropTypes.shape({
			collapsed: PropTypes.bool.isRequired,
			links: PropTypes.arrayOf(PropTypes.shape({
				to: PropTypes.string.isRequired,
				value: PropTypes.string.isRequired
			})).isRequired
		}).isRequired
	},


	render: function(){

		return(
			<div className="header">
				<Link to="/?onsite=true" className="logo">
					<div className="w">w</div>
					<div className="wandel">andel</div>
					<div className="mut-location">
						<div className="mut">mut</div>
						<div className="location">{this.props.location || 'Würzburg'}</div>
					</div>
				</Link>
				<Menu
					collapsed={this.props.menu.collapsed}
					links={this.props.menu.links}
				/>
			</div>
		)
	}

});

module.exports = Header;