/**
 * DATENBANK: Projekte und Initiativen
 * [0] REPAIR CAFE (Transition)
 * [1] FOODSHARING (Foodsharing)
 * [2] FREIRAUM (Freiraum)
 * [3] FREIRAD (Luftschloss)
 * [4] URBAN GARDENING (Stadtgärtner)
 * [5] KLEIDERTAUSCH (Transition)
 * [6] UMSONSTLADEN (Luftschloss)
 * [7] COMPUTERSPENDE (angestöpselt)
 * [8] FABLAB (Nerd2Nerd)
 */

module.exports = [
	/**
	 * REPAIR CAFE
	 */
	{
		id: 0,
		linkTo: '/project/0',

		teaserImgSrc: '/assets/img/teaser/repair-cafe-teaser.jpeg',
		projectName: 'repair café',
		projectDescription: 'reparieren statt wegwerfen',

		
		owner: 'Transition Würzburg Initiative',

		details: [
			{
				title: 'DIE IDEE',
				value: 'In einer Selbsthilfewerkstatt werden defekte Geräte und Gegenstände gemeinsam mit Experten repariert. ' +
				'Denn häufig werden kaputte Sachen nur entsorgt, weil das Know-how fehlt oder die Reparatur zu teuer ist. ' +
				'Weltweit gibt es bereits über 1000 Repair Cafés. ' +
				'Seit 2014 findet es zwei Mal im Jahr auch in Würzburg statt. ' +
				'Für die Wartezeit werden Workshops und ein Unterhaltungsprogramm angeboten, außerdem gibt es Kaffee und Kuchen.'
			},
			{
				title: 'MITMACHEN',
				value: 'Grundsätzlich können die Projekte in vielerlei Hinsicht unterstützt werden. ' +
				'Zum Beispiel zeitlich, räumlich, materiell oder finanziell. ' +
				'Über die Datenbank werden einige Möglichkeiten angezeigt. ' +
				'Die Initiativen sind auch offen für neue Ideen und deren aktive Mitgestaltung. ' +
				'Am Besten einfach bei einem der Kreativtreffen vorbeikommen.'

			}
		],

		contact: {
			email: 'mail@transition-wuerzburg.de',
			site: 'http://www.transition-wuerzburg.de',
			facebook: 'www.facebook.com/transitionwue',
			meetings: [
				{
					title: 'KREATIVTREFFEN',
					value: [
						'Jeden 2. und 4. Sonntag im Monat', 'von 16:00 – 18:00 Uhr'
					]
				}
			],
			location: ['Kellerperle, Am Studentenhaus 1', '97072 Würzburg']
		},

		/*
		donate: {
			title: 'SPENDENKONTO>',
			iban: '<iban>',
			bic: '<bic>'
		},
		*/

		attributes: {
			0: [0,1,2,3,4,5,5,6,7,10],
			1: [0,1,2,3],
			2: [0,1],
			3: [0,1,3,5],
			4: [0,1,2,3]
		}
	},
	/**
	 * FOODSHARING
	 */
	{
		id: 1,
		linkTo: '/project/1',

		teaserImgSrc: '/assets/img/teaser/foodsharing-teaser.jpeg',
		projectName: 'foodsharing',
		projectDescription: 'verschenken statt wegwerfen',


		owner: 'Foodsharing e.V. - Ortsgruppe Würzburg',

		details: [
			{
				title: 'DIE IDEE',
				value: 'Überschüssige Lebensmittel werden bei Supermärkten,  Lebensmittelbetrieben, Veranstaltungen und ' +
				'Privatpersonen abgeholt und kostenlos weiterverteilt. ' +
				'Bis heute konnten dadurch deutschlandweit bereits über vier Millionen Kilogramm Lebensmittel vor der ' +
				'Entsorgung gesichert verteilt werden. ' +
				'Foodsharing gibt es mittlerweile deutschlandweit, seit 2014 auch in Würzburg.'

			},
			{
				title: 'MITMACHEN',
				value: 'Grundsätzlich können die Projekte in vielerlei Hinsicht unterstützt werden. ' +
				'Zum Beispiel zeitlich, räumlich, materiell oder finanziell. ' +
				'Über die Datenbank werden einige Möglichkeiten angezeigt. ' +
				'Die Initiativen sind auch offen für neue Ideen und deren aktive Mitgestaltung. ' +
				'Am Besten einfach bei einem der Kreativtreffen vorbeikommen.'

			}
		],

		contact: {
			email: 'wuerzburg@lebensmittelretten.de',
			site: 'https://www.foodsharing.de',
			facebook: ': www.facebook.com/FoodsharingWuerzburg',
			meetings: [
				{
					title: 'KREATIVTREFFEN',
					value: [
						'2. Sonntag im Monat', 'In der ESG (großer Clubraum)'
					]
				}
			],
			location: ['Friedrich-Ebert-Ring 27b', '97072 Würzburg']
		},

		/*
		donate: {
			title: 'SPENDENKONTO',
			iban: '<iban>',
			bic: '<bic>'
		},
		*/

		attributes: {
			0: [0,1,3,6],
			1: [2],
			2: [0,1,2],
			3: [0,2,3,4,5],
			4: [0,3]
		}
	},
	{
		id: 2,
		linkTo: '/project/2',


		teaserImgSrc: '/assets/img/teaser/freiraum-teaser.jpeg',
		projectName: 'freiraum',
		projectDescription: 'Freizeit ohne Konsumzwang',


		owner: 'Freiraum Würzburg e.V. (i.Gr.)',

		details: [
			{
				title: 'DIE IDEE',
				value: 'Ein Raum für Musik, kreative Projekte, Kochsessions, Bier und Kaffee, für Vorträge, Konzerte, ' +
				'Sprachkurse, Workshops und Gruppentreffen. ' +
				'Der Freiraum ist ein Ort der kostenlosen Freizeitgestaltung. ' +
				'Seit 2014 hat der Freiraum drei Mal pro Woche geöffnet.'

			},
			{
				title: 'MITMACHEN',
				value: 'Grundsätzlich können die Projekte in vielerlei Hinsicht unterstützt werden. ' +
				'Zum Beispiel zeitlich, räumlich, materiell oder finanziell. ' +
				'Über die Datenbank werden einige Möglichkeiten angezeigt. ' +
				'Die Initiativen sind auch offen für neue Ideen und deren aktive Mitgestaltung. ' +
				'Am Besten einfach bei einem der Kreativtreffen vorbeikommen.'

			}
		],

		contact: {
			email: 'verein.freiraum@gmx.de',
			site: 'https://www.freiraumwuerzburg.wordpress.com',
			facebook: 'www.facebook.com/FreiRaumWuerzburg',
			meetings: [
				{
					title: 'KREATIVTREFFEN',
					value: [
						'spontan'
					]
				},
				{
					title: 'ÖFFNUNGSZEITEN',
					value: [
						'Mittwoch, Samstag und Sonntag', '16:00 - 22:00'
					]
				}
			],
			location: ['Maiergasse 2', '97070 Würzburg']
		},

		donate: {
			title: 'SPENDENKONTO',
			iban: 'DE08 4306 0967 6040 2933 00',
			bic: 'GENODEM1GLS',
			bank: 'GLS Gemeinschaftsbank Bochum'
		},

		attributes: {
			0: [0,1,2,3,4,5,6,7,8,10],
			1: [0,1,2,3],
			2: [0,1,2],
			3: [3,4,5],
			4: [1,3]
		}
	},
	{
		id: 3,
		linkTo: '/project/3',


		teaserImgSrc: '/assets/img/teaser/freirad-teaser.jpeg',
		projectName: 'freirad',
		projectDescription: 'Leihräder im Stadtraum',


		owner: 'Luftschloss e.V.',

		details: [
			{
				title: 'DIE IDEE',
				value: 'Gespendete Fahrräder werden repariert, gekennzeichnet und kostenlos im Stadtraum zur Nutzung angeboten. ' +
				'Nach einmaliger Registrierung können die Leihräder bis zu 24 Stunden geliehen werden. ' +
				'Im Anschluss werden sie zu einer Leihstation zurück gebracht. ' +
				'Die Fahrräder ermöglichen den spontanen Zugang zu Mobilität im Stadtraum. ' +
				'Das kostenlose Leihrad-System gibt es in vielen Städten, seit 2013 auch in Würzburg.'
			},
			{
				title: 'MITMACHEN',
				value: 'Grundsätzlich können die Projekte in vielerlei Hinsicht unterstützt werden. ' +
				'Zum Beispiel zeitlich, räumlich, materiell oder finanziell. ' +
				'Über die Datenbank werden einige Möglichkeiten angezeigt. ' +
				'Die Initiativen sind auch offen für neue Ideen und deren aktive Mitgestaltung. ' +
				'Am Besten einfach bei einem der Kreativtreffen vorbeikommen.'

			}
		],

		contact: {
			email: 'info@freirad.net',
			site: 'http://www.freirad.net',
			facebook: 'www.facebook.com/Freirad-das-Verleihrad-508103822569321',
			meetings: [
				{
					title: 'FAHRRAD-SELBSTHILFEWERKSTATT',
					value: [
						'Jeden Donnerstag 17-19 Uhr'
					]
				}
			],
			location: ['Gutenbergstr. 3', '97080 Würzburg']
		},

		donate: {
			title: 'SPENDENKONTO',
			iban: 'DE02 4306 0967 6027 0758 00',
			bic: 'GENODEM1GLS',
			bank: 'Bank: GLS Gemeinschaftsbank Bochum'
		},

		attributes: {
			0: [0,1,2,3,7],
			1: [1,2,3],
			2: [1,2],
			3: [1,3,5],
			4: [0,1,2,3]
		}
	},
	{
		id: 4,
		linkTo: '/project/4',


		teaserImgSrc: '/assets/img/teaser/urban-gardening-teaser.jpeg',
		projectName: 'urban gardening',
		projectDescription: 'Nutzpflanzen im Stadtraum',


		owner: 'Stadtgärtner Würzburg e.V.',

		details: [
			{
				title: 'DIE IDEE',
				value: 'Bei Urban Gardening (Urbaner Gartenbau) wird der Stadtraum durch Nutzpflanzen begrünt. ' +
				'So soll dem konventionellen Angebot eine regionale Alternative gegenüber gestellt werden. ' +
				'Neben der aktiven Mitgestaltung des Stadtraumes kann so ein freier Zugang zu ' +
				'Lebensmitteln ermöglicht werden. ' +
				'Urban Gardening gibt es in mehreren Städten, seit 2012 auch in Würzburg.'
			},
			{
				title: 'MITMACHEN',
				value: 'Grundsätzlich können die Projekte in vielerlei Hinsicht unterstützt werden. ' +
				'Zum Beispiel zeitlich, räumlich, materiell oder finanziell. ' +
				'Über die Datenbank werden einige Möglichkeiten angezeigt. ' +
				'Die Initiativen sind auch offen für neue Ideen und deren aktive Mitgestaltung. ' +
				'Am Besten einfach bei einem der Kreativtreffen vorbeikommen.'

			}
		],

		contact: {
			email: 'urbangardeningwuerzburg@posteo.de',
			site: 'http://www.stadtgaertner-wuerzburg.de',
			facebook: 'www.facebook.com/StadtgaertnerWuerzburg',
			meetings: [
				{
					title: 'KREATIVTREFFEN',
					value: [
						'Jeden 1., 3. und 5. Donnerstag im Monat'
					]
				}
			],
			location: ['Café Cairo', 'Fred-Joseph-Platz 3', '97082 Würzburg']
		},

		donate: {
			title: 'SPENDENKONTO',
			iban: 'DE31 7905 0000 0047 8661 73',
			bic: 'BYLADEM1SWU',
			bank: 'Sparkasse Mainfranken Würzburg'
		},

		attributes: {
			0: [0,1,2,3,4,6,9],
			1: [0,2,3],
			2: [1,2],
			3: [1,2,3,5],
			4: [0,3]
		}
	},
	{
		id: 5,
		linkTo: '/project/5',


		teaserImgSrc: '/assets/img/teaser/kleidertausch-teaser.jpeg',
		projectName: 'kleidertausch',
		projectDescription: 'tauschen statt neu kaufen',


		owner: 'Transition Würzburg Initiative',

		details: [
			{
				title: 'DIE IDEE',
				value: 'Gebrauchte Kleidung, Schuhe und Accessoires abgeben, austauschen oder mitnehmen – bei einem ' +
				'Kleidertausch wird der umweltschädigende Konsum reduziert und trotzdem die Lust auf neue ' +
				'Kleidung befriedigt. ' +
				'Die Idee wird mittlerweile in vielen Städten umgesetzt. ' +
				'In Würzburg findet der Kleidertausch seit 2014 zweimal im Jahr statt.'
			},
			{
				title: 'MITMACHEN',
				value: 'Grundsätzlich können die Projekte in vielerlei Hinsicht unterstützt werden. ' +
				'Zum Beispiel zeitlich, räumlich, materiell oder finanziell. ' +
				'Über die Datenbank werden einige Möglichkeiten angezeigt. ' +
				'Die Initiativen sind auch offen für neue Ideen und deren aktive Mitgestaltung. ' +
				'Am Besten einfach bei einem der Kreativtreffen vorbeikommen.'

			}
		],

		contact: {
			email: 'mail@transition-wuerzburg.de',
			site: 'http://www.transition-wuerzburg.de ',
			facebook: 'www.facebook.com/transitionwue',
			meetings: [
				{
					title: 'KREATIVTREFFEN',
					value: [
						'Jeden 2. und 4. Sonntag im Monat', 'von 16:00 – 18:00 Uhr'
					]
				}
			],
			location: ['Kellerperle', 'Am Studentenhaus 1', '97072 Würzburg']
		},

		/*
		donate: {
			title: 'SPENDENKONTO',
			iban: '<iban>',
			bic: '<bic>'
		},
		*/

		attributes: {
			0: [0,1,3,5],
			1: [0,1,2,3],
			2: [0,1],
			3: [1,3,5],
			4: [1,3]
		}
	},
	{
		id: 6,
		linkTo: '/project/6',


		teaserImgSrc: '/assets/img/teaser/umsonstladen-teaser.jpeg',
		projectName: 'umsonstladen',
		projectDescription: 'verschenken statt wegwerfen',


		owner: 'Luftschloss e.V.',

		details: [
			{
				title: 'DIE IDEE',
				value: 'In einem Umsonstladen werden neue und gebrauchte Gegenstände kostenlos angeboten. ' +
				'Es können Bücher, Kleidung, Haushalts- und sonstige Gegenstände gespendet oder mitgenommen werden. ' +
				'Durch die Nutzungsdauerverlängerung wird der Ressourcenverbrauch reduziert. ' +
				'Umsonstläden gibt es deutschlandweit, seit 2012 auch in Würzburg. ' +
				'Er hat zweimal pro Woche geöffnet.'
			},
			{
				title: 'MITMACHEN',
				value: 'Grundsätzlich können die Projekte in vielerlei Hinsicht unterstützt werden. ' +
				'Zum Beispiel zeitlich, räumlich, materiell oder finanziell. ' +
				'Über die Datenbank werden einige Möglichkeiten angezeigt. ' +
				'Die Initiativen sind auch offen für neue Ideen und deren aktive Mitgestaltung. ' +
				'Am Besten einfach bei einem der Kreativtreffen vorbeikommen.'

			}
		],

		contact: {
			email: 'umsonstladen.wuerzburg@posteo.de',
			site: 'https://www.umsonstladen4wuerzburg.wordpress.com',
			facebook: 'www.facebook.com/Luftschoss.Wuerzburg',
			meetings: [
				{
					title: 'KREATIVTREFFEN',
					value: [
						'Jeden 1. Dienstag im Monat ab 18 Uhr'
					]
				},
				{
					title: 'ÖFFNUNGSZEITEN',
					value: [
						'Donnerstag 17-19 Uhr',
						'Samstag 12-14 Uhr'
					]
				}
			],
			location: ['Gutenbergstr. 3', '97080 Würzburg']
		},

		donate: {
			title: 'SPENDENKONTO',
			iban: 'DE02 4306 0967 6027 0758 00',
			bic: 'GENODEM1GLS',
			bank: 'GLS Gemeinschaftsbank Bochum'
		},

		attributes: {
			0: [0,1,3,11],
			1: [0,1,2,3],
			2: [1,2],
			3: [3,5],
			4: [1,3]
		}
	},
	{
		id: 7,
		linkTo: '/project/7',


		teaserImgSrc: '/assets/img/teaser/computerspende-teaser.jpeg',
		projectName: 'computerspende',
		projectDescription: 'reparieren und Bedürftigen schenken',


		owner: 'Angestöpselt – Verein für Digitalkompetenz e.V.',

		details: [
			{
				title: 'DIE IDEE',
				value: 'Gespendete Rechner werden aufbereitet und an Bedürftige verschenkt. ' +
				'Neben der Ressourcenschonung bekommen so wirtschaftlich benachteiligte Menschen die Chance am digitalen ' +
				'Leben teilzuhaben. ' +
				'Das Projekt gibt es in vielen Städten. ' +
				'In Würzburg gibt es die Computerspende seit 2011.'
			},
			{
				title: 'MITMACHEN',
				value: 'Grundsätzlich können die Projekte in vielerlei Hinsicht unterstützt werden. ' +
				'Zum Beispiel zeitlich, räumlich, materiell oder finanziell. ' +
				'Über die Datenbank werden einige Möglichkeiten angezeigt. ' +
				'Die Initiativen sind auch offen für neue Ideen und deren aktive Mitgestaltung. ' +
				'Am Besten einfach bei einem der Kreativtreffen vorbeikommen.'

			}
		],

		contact: {
			email: 'info@angestoepselt.de',
			site: 'https://www.computerspendewuerzburg.wordpress.com',
			facebook: 'www.facebook.com/computerspende.wuerzburg',
			meetings: [
				{
					title: 'ÖFFNUNGSZEITEN',
					value: [
						'Montag und Mittwoch 18:30 – 20:30'
					]
				}
			],
			location: ['Frankfurter Straße 74', '97082 Würzburg']
		},

		donate: {
			title: 'SPENDENKONTO',
			iban: 'DE23 7905 0000 0047 3098 28',
			bic: 'BYLADEM1SWU',
			bank: 'Sparkasse Mainfranken Würzburg'
		},

		attributes: {
			0: [0,1,2,3,7,8],
			1: [0,1,2,3],
			2: [1,2],
			3: [1,3,5],
			4: [1,3]
		}
	},
	{
		id: 8,
		linkTo: '/project/8',


		teaserImgSrc: '/assets/img/teaser/fablab-teaser.jpeg',
		projectName: 'fablab',
		projectDescription: 'Produkte selbst herstellen',


		owner: 'Nerd2Nerd e.V.',

		details: [
			{
				title: 'DIE IDEE',
				value: 'Durch eine offene Werkstatt wird der Zugang zu aktuellen Technologien und modernen industriellen ' +
				'Produktionsverfahren ermöglicht. ' +
				'Dazu zählen 3D-Drucker, Laser-Cutter, CNC- und Fräsmaschinen, womit Eigenproduktionen und Ersatzteile ' +
				'selbst hergestellt werden können. ' +
				'Weltweit gibt es bereits über 360 FabLabs. ' +
				'In Würzburg hat es seit 2014 jeden Samstag geöffnet.'

			},
			{
				title: 'MITMACHEN',
				value: 'Grundsätzlich können die Projekte in vielerlei Hinsicht unterstützt werden. ' +
				'Zum Beispiel zeitlich, räumlich, materiell oder finanziell. ' +
				'Über die Datenbank werden einige Möglichkeiten angezeigt. ' +
				'Die Initiativen sind auch offen für neue Ideen und deren aktive Mitgestaltung. ' +
				'Am Besten einfach bei einem der Kreativtreffen vorbeikommen.'

			}
		],

		contact: {
			email: 'info@fablab-wuerzburg.de',
			site: 'https://www.fablab-wuerzburg.de ',
			meetings: [
				{
					title: 'KREATIVTREFFEN',
					value: [
						'Jeden Donnerstag ab 18:30 Uhr'
					]
				},
				{
					title: 'OPENLAB (OFFENES FABLAB)',
					value: [
						'Jeden Samstag von 14:00 – 18:00 Uhr'
					]
				},
				{
					title: '3D-DRUCKER-TREFF',
					value: [
						'Jeden 3. Mittwoch im Monat'
					]
				}
			],
			location: ['FabLab Würzburg', 'Veitshöchheimer-Str. 14', '97080 Würzburg']
		},

		donate: {
			title: 'SPENDENKONTO',
			iban: 'DE35 7905 0000 0047 7641 54',
			bic: 'BYLADEM1SWU',
			bank: 'Sparkasse Mainfranken Würzburg'
		},

		attributes: {
			0: [0,1,2,7,8],
			1: [0,1,2,3],
			2: [0,1,2],
			3: [1,3,5],
			4: [1,3]
		}
	}
];
