var landingPage = require('./landingPage'),
	projectOverview = require('./projectOverview'),
	peopleOverview = require('./peopleOverview'),
	participate = require('./participate'),
	financial = require('./financial'),
	attributes = require('./attributes'),
	backgroundInfo = require('./backgroundInfo');



exports.getMenuLinks = function() {
	return [
		{
			to: '/impressum',
			value: 'Impressum'
		}
	];
};


exports.landingPage = function () {
	return landingPage;
};

exports.projectOverview = function () {
	return projectOverview;
};

exports.peopleOverview = function () {
	return peopleOverview;
};

exports.participate = function () {
	return participate;
};

exports.financial = function () {
	return financial;
};

exports.attributes = function () {
	return attributes;
};

exports.info = function () {
	return backgroundInfo;
};