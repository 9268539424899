var React = require('react'),
	PropTypes = require('react').PropTypes;

var Filter = React.createClass({

	propTypes: {
		
		activeValue: PropTypes.shape({
			value: PropTypes.string.isRequired,
			id: PropTypes.number.isRequired
		}),
		otherValues: PropTypes.arrayOf(PropTypes.shape({
			value: PropTypes.string.isRequired,
			id: PropTypes.number.isRequired
		})),
		
		onFilterChange: PropTypes.func.isRequired,

		expanded: PropTypes.bool,
		extraClassName: PropTypes.string
	},

	onFilterSelect: function (targetId) {
		this.setState({expanded: !this.state.expanded});
		this.props.onFilterChange(targetId);
	},

	defaultProps: {
		expanded: false
	},

	getInitialState: function () {
		return {
			expanded: this.props.expanded
		}
	},

	render: function () {

		var outerClassName = 'single-filter ' + this.props.extraClassName,
			optionsClassName = 'options ',
			selectedClassName = 'selected ';

		optionsClassName += this.state.expanded ? 'expanded' : 'collapsed';
		selectedClassName += this.state.expanded ? 'expanded' : 'collapsed';

		return (
			<div className={outerClassName}>

				<div className="select">
					<div className={selectedClassName}
						 onClick={(function() {
						   this.setState({expanded: !this.state.expanded});
						 }).bind(this)}
					>
						{this.props.activeValue.value}
					</div>
				</div>

				<div className={optionsClassName}>
					{this.props.otherValues.map(function (other) {
						return(
							<div className="option" key={other.id}
								 onClick={this.onFilterSelect.bind(this, other.id)}
							>
								{other.value}
							</div>
						)
					}, this)}
				</div>

			</div>
		)
	}
});

module.exports = Filter;


/*
 <select
 value={this.props.activeValue.id}
 onChange={this.props.onFilterChange}
 >
 <option value={this.props.activeValue.id}>{this.props.activeValue.value}</option>


 {this.props.otherValues.map(function (element) {
 return(
 <option key={element.id} value={element.id}>{element.value}</option>
 )
 })}
 </select>
 */