module.exports = {

	header: require('./header'),

	headline: {
		title: 'Welche Idee gefällt mir für Würzburg?'
	},

	slider: require('./projects'),

	content: [

		{
			type: 'contentText',
			value: {
				headline: 'wandelmut',
				value: 'ist eine Onlineplattform, die zukunftsorientierte Projekte in Würzburg vorstellt. \u00BBDo-it-Yourself\u00AB und \u00BBDo-it-Together\u00AB lautet das Motto, nach dem einige Pioniere die Stadt bereits umgestalten. Würzburg ist somit Teil einer weltweit wachsenden Bewegung, die mit kreativen urbanen Projekten neue ökologische und soziale Trends setzt. Die Entwicklung zeigt: je mehr Menschen sich einbringen, desto umfangreicher kann Würzburg sich wandeln.'
			}
		},

		{
			type: 'contentBig',
			value: [
				{
					type: 'text',
					value: 'Kann ich mich '
				},
				{
					type: 'link',
					value: 'persönlich ',
					to: '/participate'
				},
				{
					type: 'text',
					value: 'oder '
				},
				{
					type: 'link',
					value: 'finanziell ',
					to: '/financial'
				},
				{
					type: 'text',
					value: 'einbringen?'
				}
			]
		},

		{
			type: 'contentParallel',
			value: {
				left: {
					headline: {
						to: '/people',
						value: 'Wer'
					},
					value: 'Menschen in Würzburg, die mit Veränderungen angefangen haben, erzählen ihre Geschichte.'
				},
				right: {
					headline: {
						to: '/info',
						value: 'Warum'
					},
					value: 'Würzburg trägt dazu bei, globale Herausforderungen anzugehen. Hier gibt es weitere Informationen.'
				}
			}
		}

	],

	social: {
		imgSrc: '/assets/img/ntwrk-icon-fbck.png',
		to: '/impressum',
		value: 'Keine Geschichte verpassen'
	},

	footer: require('./footer')


};