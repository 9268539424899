var React = require('react');

var ContentBig = React.createClass({

	render: function () {
		return (
			<div className="content-big">
				<div className="text">{this.props.children}</div>
			</div>
		)
	}

});

module.exports = ContentBig;