var React = require('react'),
	PropTypes = require('react').PropTypes,

	Header = require('../header/header.jsx'),
	Footer = require('../footer/footer.jsx');


/*
 * A page is the container used to render all pages into.
 * It consists of the menu bar, the actual pages content (children)
 * and the Impressum link
 */
var Page = React.createClass({

	propTypes: {
		header: PropTypes.shape({
			location: PropTypes.string,
			menu: PropTypes.shape({
				collapsed: PropTypes.bool.isRequired,
				links: PropTypes.arrayOf(PropTypes.shape({
					to: PropTypes.string.isRequired,
					value: PropTypes.string.isRequired
				})).isRequired
			}).isRequired
		}).isRequired,

		footer: PropTypes.shape({
			copyright: PropTypes.string,
			security: PropTypes.shape({
				value: PropTypes.string.isRequired,
				to: PropTypes.string.isRequired
			}).isRequired,
			small: PropTypes.bool
		}).isRequired
	},

	render: function(){

		return(
			<div>
				<main>
					<div className="inner-body">
						<Header
							location={this.props.header.location}
							menu={this.props.header.menu}
						/>
						{this.props.children}
					</div>
				</main>
				<Footer
					copyright={this.props.footer.copyright}
					security={this.props.footer.security}
					contributors={this.props.footer.contributors}
					small={this.props.footer.small || false}
				/>
			</div>
		)
	}

});

module.exports = Page;