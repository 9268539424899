var React = require('react'),
	Link = require('react-router').Link;


var Menu = React.createClass({

	propTypes: {
		links: React.PropTypes.arrayOf(function(propValue, key, componentName, location, propFullName){
			if (!propValue[key]['to'] || !propValue[key]['value']) {
				return new Error(
					'Invalid prop `' + propFullName + '` supplied to' +
					' `' + componentName + '`. Expected Links as {to:"", value:""}. Validation failed.'
				);
			}
		}).isRequired
	},

	// collapsed: React.PropTypes.bool,

	createLinks: function(links) {
		return links.map(function(entry){
			return <Link key={entry.to} className="link" to={entry.to} onClick={(function() {
			  this.setState({collapsed: !this.state.collapsed});
			}).bind(this)}>{entry.value}</Link>
		});
	},

	onMenuIconClick: function () {
		this.setState({collapsed: !this.state.collapsed});
	},

	getInitialState: function () {
		return {
			collapsed: true
		}
	},

	render: function(){

		var links = this.createLinks(this.props.links);

		var menuClass = this.state.collapsed ? 'menu collapsed' : 'menu expanded';

		return(
			<div className={menuClass}>
				<button onClick={this.onMenuIconClick}
					 className="c-hamburger c-hamburger--htx">
					<span></span>
				</button>
				<div className="links">
					{links}
				</div>
			</div>
		)
	}

});

module.exports = Menu;