var React = require('react'),

	data = require('../data/data').peopleOverview(),

	Link = require('react-router').Link,
	browserHistory = require('react-router').browserHistory,

	Page = require('./page/page.jsx'),
	Headline = require('./onpage/headline/headline.jsx'),

	Slider = require('./onpage/slider/slider.jsx');




var Person = React.createClass({


	handleClick: function(dir) {
		var personId = this.state.personId;
		dir === 'left' ? this.previous(personId) : this.next(personId);
	},

	next: function (current) {
		var personId = current < data.people.length-1 ? ++current : 0;
		this.setState({personId: personId});
		browserHistory.push('/person/' + personId);
	},

	previous: function (current) {
		var personId = current > 0 ? --current : data.people.length-1;
		this.setState({personId: personId});
		browserHistory.push('/person/' + personId);
	},
	



	getInitialState: function () {
		return {
			personId: this.props.params.pid
		};
	},
	

	nextPersonId: function (current) {
		return current < data.people.length-1 ? ++current : 0;
	},

	previousPersonId: function (current) {
		return current > 0 ? --current : data.people.length-1;
	},

	render: function () {

		return(
			<Page
				header={data.header}
				footer={data.footer}
			>
				<div className="person">
				
					<Headline title="">
						<Link to="/people">Alle Portraits</Link>
					</Headline>
	
	
					<Slider
						onClickLeft={this.handleClick.bind(this, 'right')}
						onClickRight={this.handleClick.bind(this, 'left')}>
						<div className="person-image">
							<img src={data.people[this.state.personId].imgSrc}>
							</img>
						</div>
					</Slider>

					<div className="person-description">
						<div classID="text">
							<div className="quotation-mark-begin">&ldquo;</div>
							{data.people[this.state.personId].descriptions.map(function (description) {
								return(
									<div className="description" key={description}>
										{description}
									</div>
								)
							})}
							<div className="quotation-mark-end">&rdquo;</div>
						</div>

						<Link
							className="project-link"
							to={'project/' + data.people[this.state.personId].projectIds[0]}>
							{data.people[this.state.personId].name}
						</Link>
					</div>
					


				</div>


			</Page>

		)
	}

});

module.exports = Person;


/*
 <div className="teaser">
 <Link to={data.people[this.previousPersonId(this.props.params.pid)].linkTo}>W</Link>
 <img src={data.people[this.props.params.pid].imgSrc} />
 <Link to={data.people[this.nextPersonId(this.props.params.pid)].linkTo}>W</Link>
 {data.people[this.props.params.pid].description}
 </div>
 */