var React = require('react'),
	key = 0;

// TODO use different grid key to prevent server-client-break when reloading page with grid directly

var Grid = React.createClass({

	/**
	 * Pass an array of children 1-n that will be rendered as grid elements
	 * Whatever is used as children will be rendered as they are
	 */

	propTypes: {
		columns: React.PropTypes.number
	},

	// TODO make grid columns

	render: function () {



		return (
			<div className="grid">

				<div className="container">
				{this.props.children.map(function (child) {
					return(
						<div key={'grid-el-' + key++} className="element">
							{child}
						</div>
					)
				})}
				</div>


			</div>
		)
	}

});

module.exports = Grid;