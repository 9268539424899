module.exports = {

	copyright: '\u00A9 2017 Wandelmut',
	security: {
		value: 'Datenschutz',
		to: '/datasecurity'
	},
	contributors: {
		value: 'Mitwirkende',
		to: '/contributors'
	},
	small: false

};
