var React = require('react'),
	Link = require('react-router').Link,

	data = require('../data/data').financial(),
	attributes = require('../data/data').attributes(),
	projects = require('../data/data').projectOverview().projects,

	Page = require('./page/page.jsx'),
	Headline = require('./onpage/headline/headline.jsx'),
	MultiFilter = require('./onpage/headline/multiFilter.jsx'),
	ProjectTeaser = require('./onpage/projectTeaser/projectTeaser.jsx'),
	Grid = require('./onpage/grid/grid.jsx');



var Financial = React.createClass({

	updateVisibleProjects: function () {

		// filter the projects to find those which apply to the filter
		var filteredProjects = projects.filter(function (project) {

			var isInFilter = true;

			// apply all filter criteria to this project
			// iterate all filter questions
			this.state.filters.forEach(function (filter) {

				var targetAttrType = filter.targetAttrType;

				// each questions can have multiple filter citeria
				filter.values.forEach(function (filterValue) {
					if(filterValue.selected && project.attributes[targetAttrType].indexOf(filterValue.id) < 0) {
						// filter is active and project does not apply to this criterion
						isInFilter = false;
					}
				});
			});

			return isInFilter;

		}, this);

		this.setState({projects: filteredProjects});
	},

	onFilterChange: function (targetAttrType, id, checked) {

		var filters = this.state.filters;
		filters[0].values  =filters[0].values.map(function (value) {
			if(value.id !== id) {return value;}
			value.selected = checked;
			return value;
		});

		this.setState({filters: filters});

		this.updateVisibleProjects();
	},

	onDeselectAll: function (targetAttrType) {
		var attributes = this.attributeArray(targetAttrType);
		attributes.forEach(function (attribute, index) {
			this.onFilterChange(targetAttrType, index, false);
		}, this);
	},

	objectAsArray: function (obj, transformFn) {
		var arr = [];
		for(var key in obj) {
			if(!obj.hasOwnProperty(key)) continue;
			arr.push(transformFn(key, obj[key]));
		}
		return arr;
	},

	attributeArray: function (targetAttrType) {
		return this.objectAsArray( attributes[targetAttrType], function (key, value) {
			return {
				id: Number.parseInt(key, 10),
				value: value,
				selected: false
			}
		});
	},

	getDefaultFilter: function (targetAttrType) {
		return {
			targetAttrType: targetAttrType,
			question: data.questions[0].value,
			values: this.attributeArray(targetAttrType)
		}
	},

	getFilterCriteria: function () {
		var filter = [];
		for(var key in attributes[1]) {
			filter.push({
				id: Number.parseInt(key, 10),
				value: attributes[1][key]
			})
		}
		return filter;
	},

	getInitialState: function () {

		var filters = [];

		data.questions.forEach(function (question) {
			filters.push(this.getDefaultFilter(question.targetAttrType));
		}, this);

		return {
			filters: filters,
			projects: projects
		}

	},

	render: function(){

		return(
			<Page
				header={data.header}
				footer={data.footer}
			>
				<Headline title=""></Headline>

				<div className="participate">

					<div className="participate-left">

						<div className="participate-teasers">
							{data.teasers.map(function (teaser) {
								return(
									<div
										key={teaser.value}
										className="teaser"
									>
										{teaser.value}
									</div>
								)
							}, this)}
						</div>

						<div className="participate-filter">

							{this.state.filters.map(function (filter, index) {
								return(
									<div className="filter" key={index}>

										<MultiFilter

											question={filter.question}
											values={filter.values}

											onFilterChange={(function(targetAttrType, e) {
											var id = Number.parseInt(e.target.value, 10),
												checked = e.target.checked;
											this.onFilterChange(targetAttrType, id, checked);
										}).bind(this, filter.targetAttrType)}

											onDeselectAll={(function(targetAttrType) {
											this.onDeselectAll(targetAttrType);
										}).bind(this, filter.targetAttrType)}

											extraClassName="participate-filter"
										/>
									</div>
								)
							}, this)}

						</div>

					</div>


					<div className="participate-right">

						<div className="projects">
							<Grid>
								{this.state.projects.map(function (project) {
									return(
										<ProjectTeaser
											key={'project-'+project.id}
											teaserImgSrc={project.teaserImgSrc}
											projectName={project.projectName}
											projectDescription={project.projectDescription}
											linkTo={project.linkTo}
											showAll={true}
											showSubheadline={false}
										/>
									)
								})}
							</Grid>
						</div>

					</div>

				</div>







			</Page>
		)
	}

});

module.exports = Financial;


/*
 <Filter
 activeValue={this.state.activeValue}
 otherValues={this.state.otherValues}
 onFilterChange={this.onFilterChange}
 />
 */