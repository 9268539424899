var React = require('react'),

	data = require('../data/data').participate(),
	attributes = require('../data/data').attributes(),
	projects = require('../data/data').projectOverview().projects,

	Page = require('./page/page.jsx'),
	Headline = require('./onpage/headline/headline.jsx'),
	MultiFilter = require('./onpage/headline/multiFilter.jsx'),
	ProjectTeaser = require('./onpage/projectTeaser/projectTeaser.jsx'),
	Grid = require('./onpage/grid/grid.jsx');



var Participate = React.createClass({


	updateVisibleProjects: function () {

		//console.log('update visible projects')

		var filteredProjects = this.isDefaultFilter(this.state.filters) 
			? projects 
			: this.filterProjects(this.state.filters, projects);
		

		this.setState({projects: filteredProjects});
		
		
		// upate count on filters after updating projects
		var filters = this.state.filters;
		
		filters.forEach(function (filter) {
			
			//console.log('update count on filter ' + filter.targetAttrType);
		
			filter.values = filter.values.map(function (value) {
				
				//console.log('check projects regarding question/attribute ' + value.id + ', ' + 
				//  value.value+ ', ' + value.selected+ ', ' + value.count);
				
				var count = 0;
				
				// how many projects have this specific attribute?
				filteredProjects.forEach(function (project) {
					if(project.attributes[filter.targetAttrType].indexOf(value.id) >= 0) {
						count++;
					}
					//console.log('project ' + project.projectName + '[' + filter.targetAttrType + '|' + value.id + ']');
				});
				//console.log('found ' + count + ' projects');
				
				value.count = count;
				return value;
			});
		
		});

		this.setState({filters: filters});
	},
	
	
	/* this function used to OR the questions while AND-ing the filters
	filterProjects: function (filters, projects) {

		// filter the projects by each question
		// filters themselves are connected as AND, the questions are OR connected

		filters.forEach(function (filter, idx) {

			// applying each filter will reduce the number of displayed projects

			var activeQuestions = [];
			filter.values.forEach(function (q) {
				if(q.selected) activeQuestions.push(q);
			});

			// skip here if no question is active
			// consider the filter as deactivated
			if(activeQuestions.length <= 0) {
				console.log('no active questions for filter: ' + idx);
				return;
			}


			var targetAttrType = filter.targetAttrType;

			// assume no project shall be displayed
			var filteredProjects = [];

			// look at each (remaining) project and see if any question applies and it shall be included
			projects.forEach(function (project) {

				var isInFilter = false;

				activeQuestions.forEach(function (q) {
					// see if the project has an attribute that applies to the active question
					if(project.attributes[targetAttrType].indexOf(q.id) >= 0) {
						isInFilter = true;
					}
				});

				if(isInFilter) { filteredProjects.push(project) }
			});

			// we found all projects that should be displayed for this filter
			// that are all that have any matching active question criteria
			// override the projects now with the filtered ones as filters are connected
			// via AND
			projects = filteredProjects;

		});

		return projects;
	},
	*/
	
	/* this function "AND"s questions over all filters */
	filterProjects: function (filters, projects) {

		// filter the projects by each question using logical AND
		filters.forEach(function (filter, idx) {
			
			//console.log('check filter ' + idx);
			//console.log('projects have length ' + projects.length);

			filter.values.forEach(function (q) {
				//console.log('check question ' + q.value);
				if(q.selected) {
					//console.log("selected");
					var targetAttrType = filter.targetAttrType;
					
					// filter all projects that do not answer on current question q
					projects = projects.filter(function (project) {
						var result =  project.attributes[targetAttrType].indexOf(q.id) >= 0;
						//console.log('filter-result for project ' + project.projectName + ' is ' + result);
						return result;
					});
				}
				else
				{
					// question is not checked
					console.log("NOT selected");
					return;
				}
				//console.log('projects after filtering have length ' + projects.length);
				
			});
		});

		return projects;
	},

	
	onFilterChange: function (targetAttrType, id, checked) {

		var filters = this.state.filters;
		
		filters[targetAttrType].values = filters[targetAttrType].values.map(function (value) {
			if(value.id !== id) {return value;}
			
			value.selected = checked;
			return value;
		});

		this.setState({filters: filters});

		this.updateVisibleProjects();
	},

	onDeselectAll: function (targetAttrType) {
		var attributes = this.attributeArray(targetAttrType);
		attributes.forEach(function (attribute, index) {
			this.onFilterChange(targetAttrType, index, false);
		}, this);
	},



	objectAsArray: function (obj, transformFn) {
		var arr = [];
		for(var key in obj) {
			if(!obj.hasOwnProperty(key)) continue;
			arr.push(transformFn(key, obj[key]));
		}
		return arr;
	},
	
	attributeArray: function (targetAttrType) {
		return this.objectAsArray( attributes[targetAttrType], function (key, value) {
			var id = parseInt(key, 10);
			var count = 0;
			
			// how many projects have this specific attribute?
			projects.forEach(function (project) {
				//console.log('check project ' + project.projectName + ', key ' + targetAttrType + ' id ' + id);
				if(project.attributes[targetAttrType].indexOf(id) >= 0) {
					count++;
				}
			});
			
			return {
				id: id,
				value: value,
				selected: false,
				count: count
			}
		});
	},

	getDefaultFilter: function (targetAttrType) {
		return {
			targetAttrType: targetAttrType,
			question: data.questions[targetAttrType].value,
			values: this.attributeArray(targetAttrType)
		}
	},

	isDefaultFilter: function (filters) {

		var isDefault = true;

		// search for any question that is selected
		filters.forEach(function (f) {
			f.values.forEach(function (q) {
				// if any, then not default filter
				//console.log('is NOT default filter');
				if(q.selected) isDefault = false;
			});
		});
		// none? => then default filter

		return isDefault;
	},

	/* TODO: understand why this function is not regarded as a function when used with this.getProjectCountForFilter(...)
	getProjectCountForFilter: function (targetAttrType, id) {
		var count = 0;
		
		// how many projects have this specific attribute?
		projects.forEach(function (project) {
			//console.log('check project ' + project.projectName + ', key ' + targetAttrType + ' id ' + id);
			if(project.attributes[targetAttrType].indexOf(id) >= 0) {
				count++;
			}
		});
		
		//console.log('found ' + count + ' projects having attribute ' + id);
		
		return count;
	},
	*/


	getInitialState: function () {

		var filters = [];

		data.questions.forEach(function (question) {
			filters.push(this.getDefaultFilter(question.targetAttrType));
		}, this);

		return {
			filters: filters,
			isDefault: true,
			projects: projects
		}
	},










	render: function(){

		return(
			<Page
				header={data.header}
				footer={data.footer}
			>
				<Headline title=""></Headline>

				<div className="participate">

					<div className="participate-left">

						<div className="participate-teasers">
							{data.teasers.map(function (teaser) {
								return(
									<div
										key={teaser.value}
										className="teaser"
									>
										{teaser.value}
									</div>
								)
							}, this)}
						</div>


						<div className="participate-filter">
						{this.state.filters.map(function (filter, index) {
							return(
								<div className="filter" key={index}>

									<MultiFilter

										question={filter.question}
										values={filter.values}

										onFilterChange={(function(targetAttrType, e) {
											var id = parseInt(e.target.value, 10),
												checked = e.target.checked;
											this.onFilterChange(targetAttrType, id, checked);
										}).bind(this, filter.targetAttrType)}

										onDeselectAll={(function(targetAttrType) {
											this.onDeselectAll(targetAttrType);
										}).bind(this, filter.targetAttrType)}

										extraClassName="participate-filter"
									/>
								</div>
							)
						}, this)}
						</div>

					</div>

					<div className="participate-right">

						<div className="projects">
							<Grid>
								{this.state.projects.map(function (project) {
									return(
										<ProjectTeaser
											key={'project-'+project.id}
											teaserImgSrc={project.teaserImgSrc}
											projectName={project.projectName}
											projectDescription={project.projectDescription}
											linkTo={project.linkTo + '#meeting-info'}
											showAll={true}
											showSubheadline={false}
										/>
									)
								})}
							</Grid>
						</div>

					</div>

					<div className="clear" />

				</div>

			</Page>
		)
	}

});

module.exports = Participate;