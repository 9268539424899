/**
 * Datenbank: Personen aus den Projekten
 */
module.exports = [
	{
		id: 1,
		linkTo: '/person/1',

		projectIds: [7],

		name: 'COMPUTERSPENDE',
		attribute: 'mut',

		imgSrc: '/assets/img/people/person1-1.jpeg',
		descriptions: [
			'Ich habe schon immer gern an Computern gebastelt. ' +
			'Seit der siebten Klasse bin ich bei der Computerspende aktiv. ' +
			'Das sind jetzt schon fast drei Jahre. ' +
			'In dieser Zeit hab ich viel gelernt. ' +
			'Wir arbeiten zum Beispiel nur mit Linux. ' +
			'Das kannte ich vorher nicht, jetzt bin ich total fit darin.' +
			'Das Projekt ist ja an sich uneigennützig, aber man lernt halt auch so viel für sich selbst. ' +
			'Ich denke genau diese Kombi ist meine Motivation. ' +
			'Deshalb stecke ich auch gern viel Zeit in das Projekt.'
		]
	},
	{
		id: 2,
		linkTo: '/person/2',

		projectIds: [7],

		name: 'COMPUTERSPENDE',
		attribute: 'mut',

		imgSrc: '/assets/img/people/person1-2.jpeg',
		descriptions: [
			'Das ist jetzt vielleicht eine gewagte These, aber ich glaube nur wenige Leute würden was für andere machen, ' +
			'wenn sie nicht auch für sich selbst etwas daraus ziehen würden, auch wenn es vielleicht nur das ist anderen ' +
			'Menschen eine Freude zu machen. ' +
			'Manche sind so dankbar, dass sie im Gegenzug sogar etwas für dich machen wollen. ' +
			'Ein Mann, der von uns einen Computer bekam, hat sich zum Beispiel durch diesen Rechner ' +
			'selbstständig machen können. ' +
			'Er ist jetzt Gebäudereiniger und fährt mit seinem Lastenfahrrad und seinen Eimern durch Würzburg. ' +
			'Irgendwann kam er bei unserem Büro vorbei und hat unsere Fenster geputzt. ' +
			'So wollte er sich bedanken. ' +
			'Das ist für mich eine tolle Erfolgsgeschichte.'
		]
	},
	{
		id: 2,
		linkTo: '/person/2',

		projectIds: [1],

		name: 'FOODSHARING',
		attribute: 'mut',

		imgSrc: '/assets/img/people/person2-1.jpeg',
		descriptions: [
			'Ich war schon länger auf der überregionalen Onlineplattform von Foodsharing angemeldet, aber auf der Seite ' +
			'für Würzburg sind virtuelle Staubbälle über den Bildschirm geflogen. ' +
			'Während dieser Zeit habe ich auch angefangen zu Containern. ' +
			'Ich finde es krass, dass man damit eine Straftat begeht, wo das eigentliche Verbrechen doch das Wegwerfen ' +
			'von so vielen brauchbaren Lebensmitteln ist. ' +
			'Diese Kombination hat mich dazu gebracht Foodsharing in Würzburg zu gründen. ' +
			'Ich habe mir ein paar Bekannte zusammengesucht und einfach angefangen.'
		]
	},
	{
		id: 3,
		linkTo: '/person/3',

		projectIds: [1],

		name: 'FOODSHARING',
		attribute: 'mut',

		imgSrc: '/assets/img/people/person2-2.jpeg',
		descriptions: [
			'Die Anfangszeit war ziemlich arbeitsintensiv, aber jetzt stehen die Strukturen soweit. ' +
			'Wir haben viele Kontakte geknüpft, mit anderen Initiativen und Betrieben. ' +
			'Da habe ich einige positive Überraschungen erlebt. ' +
			'Teilweise melden sich Supermärkte sogar von sich aus, weil sie die Idee so gut finden. ' +
			'Im Moment bin ich dabei etwas kürzer zu treten. ' +
			'Ich kann viele Dinge oft erst abends organisieren, nachdem ich sie ins Bett gebracht habe. ' +
			'Leider fehlt es aber momentan an Leuten, die bereit sind Verantwortung zu übernehmen. ' +
			'Wir brauchen zum Beispiel mehr Verantwortliche die auch die Betriebe verwalten und Ansprechpartner sind.'
		]
	},
	{
		id: 4,
		linkTo: '/person/4',

		projectIds: [1],

		name: 'FOODSHARING',
		attribute: 'mut',

		imgSrc: '/assets/img/people/person2-3.jpeg',
		descriptions: [
			'Wir wohnen in einem Dorf in der Nähe von Würzburg. ' +
			'Durch meinen Einsatz lerne ich dort viele Leute kennen. ' +
			'Es entwickeln sich tolle Bekanntschaften, auch außerhalb von Foodsharing. ' +
			'Hier entsteht gerade eine schöne Teil- und Tauschmentalität. ' +
			'Das kannte ich bisher nicht, aber es wird hier langsam zur Normalität. ' +
			'Neulich haben mir ein paar neue Bekannte bei einem Flohmarkt ausgeholfen. ' +
			'Andere fragen mich ob ich ihnen etwas nähen kann. ' +
			'Ich habe das Gefühl, dadurch dass ich den Anfang gemacht und vor allem akzeptiert habe, dass die Leute mich ' +
			'erst mal komisch angeschaut haben, ist das Eis gebrochen.'
		]
	},
	{
		id: 5,
		linkTo: '/person/5',

		projectIds: [2],

		name: 'FREIRAUM',
		attribute: 'mut',

		imgSrc: '/assets/img/people/person3-1.jpeg',
		descriptions: [
			'Ich plane Veranstaltungen mit und veranstalte zum Beispiel die regelmäßige Jamsession. ' +
			'Ich spiele mehrere Instrumente, aber am liebsten Bass. ' +
			'Ansonsten kochen wir hier regelmäßig zusammen oder diskutieren in geselliger Runde über ' +
			'gesellschaftsrelevante Dinge. ' +
			'Ich höre aber auch gerne einfach nur zu, weil hier teilweise die unterschiedlichsten Lebensgeschichten ' +
			'zusammen kommen. ' +
			'Auch von älteren bzw. reiferen Leuten mit Lebenserfahrung, die viel rumgekommen sind. ' +
			'Das ist ziemlich interessant.'
		]
	},
	{
		id: 6,
		linkTo: '/person/6',

		projectIds: [2],

		name: 'FREIRAUM',
		attribute: 'mut',

		imgSrc: '/assets/img/people/person3-2.jpeg',
		descriptions: [
			'Eine Freundin hat mir von der Idee des Freiraums erzählt und dass noch Leute gesucht werden die mitmachen. ' +
			'Ich fand die Idee richtig gut und hab gesagt: ich bin dabei. ' +
			'Ich denke jeder kann sich irgendwie in die Gesellschaft einbringen. ' +
			'Außerdem ist doch keiner mit allem zufrieden was in der Stadt oder in der Gesellschaft passiert. ' +
			'Daran kann man nur etwas verändern, wenn man sich aktiv einbringt. ' +
			'Mit gutem Beispiel voran gehen ist denke ich der erste Schritt.'
		]
	},
	{
		id: 7,
		linkTo: '/person/7',

		projectIds: [5],

		name: 'KLEIDERTAUSCH',
		attribute: 'mut',

		imgSrc: '/assets/img/people/person4-1.jpeg',
		descriptions: [
			'Das letzte Kleidungsstück das ich gekauft habe war ein Hemd, das ich für ein Bewerbungsgespräch brauchte. ' +
			'Ehrlich gesagt kaufe ich keine Klamotten mehr in Läden. ' +
			'Wenn ich etwas brauche gehe ich auf Flohmärkte oder zu Kleidertauschs. ' +
			'Dort bekomme ich gebrauchte Sachen teilweise in sehr guter Qualität, fast wie neu. ' +
			'Deswegen finde ich den Kleidertausch so gut. ' +
			'Er fördert Nachhaltigkeit und macht Spaß. ' +
			'Man macht viel zusammen, die Leute sind gut gelaunt, man bekommt viel zurück hier. ' +
			'Beim ersten Kleidertausch habe ich als Besucher mitgemacht. ' +
			'Eine Freundin hat dann irgendwann angefangen die Veranstaltung mit zu organisieren. ' +
			'Sie hat in unserem Freundeskreis herumgefragt ob jemand Zeit und Lust hat mitzumachen. ' +
			'Das ist jetzt mein dritter Kleidertausch bei dem ich mithelfe. ' +
			'Ich nehme mitgebrachte Kleidung entgegen, sortiere, räume auf. ' +
			'Ich bringe mich einfach spontan da ein, wo Hände gebraucht werden.'
		]
	},
	{
		id: 8,
		linkTo: '/person/8',

		projectIds: [5],

		name: 'KLEIDERTAUSCH',
		attribute: 'mut',

		imgSrc: '/assets/img/people/person4-2.jpeg',
		descriptions: [
			'Dass ich mich hier einbringe hat denke ich einen familiären Hintergrund. ' +
			'Ich komme ursprünglich aus Russland. ' +
			'In den ersten Jahren in Deutschland wurde meine Familie von einer sozialen Gemeinde unterstützt. ' +
			'Sie veranstaltet zweimal im Jahr einen riesen Flohmarkt an dem gespendete Sachen verkauft werden. ' +
			'Der Erlös kommt Bedürftigen zugute, das hat uns damals sehr geholfen. ' +
			'Meine Eltern bringen sich seitdem dort mit ein. ' +
			'Als ich alt genug war und überblicken konnte was es zu tun gibt habe ich auch angefangen. ' +
			'Ich fahre bis heute zu den Flohmärkten um mitzuhelfen und denke mir jedes Mal, wie schade es ist, dass es ' +
			'dort so wenig junge Leute gibt, die sich mit einbringen. ' +
			'Beim Kleidertausch ist es anders herum. ' +
			'Hier sind eigentlich fast nur Leute in meinem Alter.'
		]
	},
	{
		id: 9,
		linkTo: '/person/9',

		projectIds: [0],

		name: 'REPAIR CAFÉ',
		attribute: 'mut',

		imgSrc: '/assets/img/people/person5-1.jpg',
		descriptions: [
			'Ich hatte den Traum von einem Repair Café in Würzburg. Anfangs haben immer alle gelacht, ' +
			'doch ich habe es sehr vehement angesprochen, weil ich das unbedingt machen wollte. ' +
			'Ich glaube durch das Repair Café in Nürnberg habe ich von dem Konzept gehört. ' +
			'Ich fand die Idee einfach großartig so etwas auch in Würzburg zu etablieren und habe alle Freunde angerufen, ' +
			'die handwerklich begabt sind. Wir haben uns zusammengesetzt und beschlossen es zu versuchen. ' +
			'Und plötzlich stand das Projekt einfach so und lief wie von selbst. ' +
			'Es kamen so viele Leute. ' +
			'Das war unglaublich. ' +
			'Der Bedarf ist definitiv da und es macht einfach allen Beteiligten unglaublich Spaß.'
		]
	},
	{
		id: 10,
		linkTo: '/person/10',

		projectIds: [0],

		name: 'REPAIR CAFÉ',
		attribute: 'mut',

		imgSrc: '/assets/img/people/person5-2.jpg',
		descriptions: [
			'Neulich waren wir auf einem Vernetzungstreffen für Repair Cafés in Süddeutschland. ' +
			'Auch die anderen Initiativen sagen, dass überwiegend Leute ab 60 das Angebot nutzen. ' +
			'Ich denke die älteren Generationen sind noch mit einer anderen Einstellung und Wertschätzung gegenüber Gegenständen aufgewachsen. ' +
			'Nicht so nach dem Motto: Der Föhn ist kaputt, kauf ich mir halt einen neuen. ' +
			'Da ist eher der Gedanke dahinter, den habe ich erst vor zwei Jahren gekauft, den will ich reparieren und weiternutzen. ' +
			'Außerdem kommen auch viele junge Familien zum Repair Café. ' +
			'Gerade Kinder finden es total spannend bei der Reparatur mitzuhelfen.'
		]
	},
	{
		id: 11,
		linkTo: '/person/11',

		projectIds: [4],

		name: 'URBAN GARDENING',
		attribute: 'mut',

		imgSrc: '/assets/img/people/person6-1.jpg',
		descriptions: [
			'Ich habe etwas in Würzburg gesucht das ich machen will und bin dann durch eine Arbeitskollegin zu Urban Gardening gekommen. ' +
			'Sie hat immer mal was mitgebracht aus ihrem Garten. ' +
			'Damals habe ich auch schon gerne gekocht, das war für mich dann der Reiz bei dem Projekt mitzumachen. ' +
			'Auch weil ich gerne Neues entdecke und neue Sachen lerne. ' +
			'Das habe ich in der Anfangszeit auch viel. ' +
			'Eben auch Sachen die für die meisten voll normal sind. ' +
			'Aber ich habe vieles aus der heimischen Küche nicht gekannt.'
		]
	},
	{
		id: 12,
		linkTo: '/person/12',

		projectIds: [4],

		name: 'URBAN GARDENING',
		attribute: 'mut',

		imgSrc: '/assets/img/people/person6-2.jpg',
		descriptions: [
			'Langsam wurde mir dann auch der Wert dieser Pflanzen bewusst. ' +
			'Allein durch die Veranstaltungen, zu denen wir gehen, zum Beispiel Saatgutbörsen. ' +
			'Da lernt man die Vielfalt erst kennen. ' +
			'Da kommen dann natürlich auch Themen wie Monsanto auf. ' +
			'Ich habe da zum ersten Mal über das Ausmaß von Monokulturen nachgedacht und gecheckt, ' +
			'dass die Menschheit viele Möglichkeiten verliert, wenn sie sich auf den industriellen Einheitsbrei reduziert.'
		]
	},
	{
		id: 13,
		linkTo: '/person/13',

		projectIds: [4],

		name: 'URBAN GARDENING',
		attribute: 'mut',

		imgSrc: '/assets/img/people/person6-3.jpg',
		descriptions: [
			'Ich hatte lange Zeit keine Ahnung was die Natur alles zu bieten hat. ' +
			'Mir ist irgendwann klar geworden, dass ich, falls ich mal Kinder habe, nicht mit ihnen ' +
			'durch die Gegend laufen und ihnen erklären könnte was das für Pflanzen um uns sind. ' +
			'Egal ob Baumarten oder heimische Gemüsesorten. ' +
			'Mir wurde klar, dass ich es wichtig finde, das Wissen zu erhalten und nicht dumm da zu stehen. ' +
			'Ich finde dieses Wissen sollte als Basics des Menschseins betrachtet werden.'
		]
	},
	{
		id: 14,
		linkTo: '/person/14',

		projectIds: [6],

		name: 'UMSONSTLADEN',
		attribute: 'mut',

		imgSrc: '/assets/img/people/person7-1.jpg',
		descriptions: [
			'Als Kind habe ich es geliebt bei meiner Oma zu sein. ' +
			'Ich krutsche gerne in alten Sachen rum und bei ihr habe ich immer wieder Neues entdeckt. ' +
			'Das ganze Haus war voller Dinge, die sie gehortet hat. ' +
			'Ich denke sie war durch den Krieg auf sparen eingestellt. ' +
			'Sie hat wirklich alles Mögliche aufgehoben und weiterverwendet. ' +
			'Zum Beispiel hat sie alte Schnüre benutzt um ihre Blumen festzubinden. ' +
			'Ich kann mich auch noch an einen alten Kochtopf erinnern, den sie zu einer Halterung für den Gartenschlauch umfunktioniert hat. ' +
			'Ich finde es interessant Dinge die schon da sind weiter zu benutzen oder sich einen neuen Verwendungszweck für sie zu überlegen.	' +
			'Als ich Leute über den Umsonstladen reden hörte wurde ich neugierig. ' +
			'Kurz darauf habe ich dort vorbei geschaut und gefragt ob ich mithelfen kann. ' +
			'Ich finde es toll, dass es in Würzburg einen Ort gibt, an dem man Sachen abgeben kann, ' +
			'die man selbst nicht mehr braucht, die für andere aber vielleicht noch einen Wert haben.'
		]
	},
	{
		id: 15,
		linkTo: '/person/15',

		projectIds: [8],

		name: 'FABLAB',
		attribute: 'mut',

		imgSrc: '/assets/img/people/person8-1.jpg',
		descriptions: [
			'Hersteller konzipieren Geräte häufig so, dass sie nicht repariert werden können und komplett ausgetauscht werden müssen. ' +
			'Das hat auch damit zu tun, dass nicht mehr so viel Wert darauf gelegt wird sie zu reparieren. ' +
			'Am Beispiel des Smartphones zeigt sich, dass diese Entwicklung aber auch vom Kunden gewollt wird. ' +
			'Er möchte eben alle zwei Jahre ein neues Handy. ' +
			'Warum sollte also ein Hersteller ein Gerät konzipieren, das 15 Jahre hält, wenn es den Kunden langweilt. ' +
			'Für Menschen die ihre Geräte aber länger benutzen möchten bieten wir das Know-how und das Werkzeug um es zu reparieren. ' +
			'Damit man ein Handy eben nicht wegen einer kaputten Ladebuchse wegwerfen muss. ' +
			'Der Nachhaltigkeitsaspekt ist uns sehr wichtig. ' +
			'Deshalb beteiligen wir uns auch beim Repair Café. ' +
			'Wir arbeiten generell gerne mit den anderen Nachhaltigkeits-Initiativen und der Stadt zusammen.'
		]
	},
	{
		id: 16,
		linkTo: '/person/16',

		projectIds: [8],

		name: 'FABLAB',
		attribute: 'mut',

		imgSrc: '/assets/img/people/person8-2.jpg',
		descriptions: [
			'Was uns besonders interessiert: Viele Geräte können nur so genutzt werden wie der Hersteller das möchte. ' +
			'Solange aber ein Medium nur so zu benutzen ist, wie es vorgegeben wird, ist man nicht wirklich dessen Besitzer. ' +
			'Der erste wichtige Schritt ist, dass die Menschen anfangen darüber nachzudenken. ' +
			'Solange es da keine kritische Masse gibt wird sich das nicht ändern. ' +
			'Da fängt das Hacking an, was ein Grundprinzip von uns ist. ' +
			'Das Hacking liegt in der Natur des Menschen. ' +
			'Er ist neugierig, möchte Dinge herausfinden und optimieren. ' +
			'Es ist ja eine künstliche Begrenzung, der das Hacking versucht etwas entgegen zu setzen.'
		]
	},
	{
		id: 17,
		linkTo: '/person/17',

		projectIds: [3],

		name: 'FREIRAD',
		attribute: 'mut',

		imgSrc: '/assets/img/people/person9-1.jpg',
		descriptions: [
			'Ich wollte das Fahrrad meiner Freundin reparieren. ' +
			'Da ich das Zuhause nicht hingekriegt habe bin ich zur Selbsthilfewerkstatt gegangen. ' +
			'Ehrlich gesagt wurde mir da eher schlecht als recht geholfen. ' +
			'Doch obwohl es nicht wirklich funktioniert hat bin ich dort hängen geblieben und habe angefangen beim Reparieren zu helfen. ' +
			'Zu dieser Zeit haben einige aufgehört und es hat sonst keiner regelmäßig die Werkstatt gemacht. ' +
			'Also habe ich mit zwei anderen, die immer mal da waren, die Werkstatt in zweiter Generation übernommen. ' +
			'Heute gibt es sogar schon eine dritte Generation an Fahrradreparateuren. ' +
			'Momentan sind wir in erster Linie eine Selbsthilfewerkstatt. ' +
			'Die Idee von Freirad stagniert, weil wir nicht genügend Leute sind. ' +
			'Außerdem brauchen wir dringend jemanden, der Lust hat ein bisschen Orga zu übernehmen und sich um E-Mails und Facebook zu kümmern.'
		]
	},
	{
		id: 18,
		linkTo: '/person/18',

		projectIds: [3],

		name: 'FREIRAD',
		attribute: 'mut',

		imgSrc: '/assets/img/people/person9-2.jpg',
		descriptions: [
			'Ich sehe zwei Probleme, wegen denen das Ursprungsprinzip von Freirad gerade etwas stagniert: ' +
			'1. Wir kommen nicht hinterher die Räder zu reparieren. ' +
			'Dafür bräuchten wir einen extra Tag und auf jeden Fall mehr Leute die mitmachen. ' +
			'2. Viele bringen die Räder nicht immer an die Stationen zurück. ' +
			'Ich habe das Gefühl selbst Leute die den Gedanken hinter dem Projekt kennen nutzen es länger als die vorgesehenen 24 Stunden. ' +
			'Das Rad steht dann über Wochen irgendwo rum. ' +
			'Da muss man sich selbstkritisch fragen, ob das nicht ein projektinhärentes Problem ist und etwas an der Struktur geändert werden muss.'
		]
	},
	{
		id: 19,
		linkTo: '/person/19',

		projectIds: [1],

		name: 'FOODSHARING',
		attribute: 'mut',

		imgSrc: '/assets/img/people/person10-1.jpg',
		descriptions: [
			'Früher war ich ein paar Mal Containern. ' +
			'Foodsharing ist meiner Meinung nach aber die bessere Lösung. ' +
			'Dadurch ändert sich zwar auch noch nicht unbedingt etwas am Wegwerfsystem der Supermärkte, ' +
			'aber Foodsharing macht durch die Öffentlichkeitsarbeit darauf aufmerksam. ' +
			'Ich finde es einfach total krass welche Mengen täglich entsorgt werden. ' +
			'Das hat ja auch globale Zusammenhänge. ' +
			'Wenn so viel Essen weggeworfen wird sind die Lebensmittelpreise höher und es werden viele Ressourcen und Ackerflächen unnötig verbraucht. ' +
			'Foodsharing leistet da meiner Meinung nach wertvolle Arbeit.'
		]
	},
	{
		id: 20,
		linkTo: '/person/20',

		projectIds: [1],

		name: 'FOODSHARING',
		attribute: 'mut',

		imgSrc: '/assets/img/people/person10-2.jpg',
		descriptions: [
			'Ich wurde früher schon mal durch eine Freundin auf Foodsharing aufmerksam, ' +
			'habe aber nie die Zeit dazu gefunden. ' +
			'Als ich nach Würzburg kam habe ich mir überlegt wo ich mich engagieren könnte. ' +
			'Da die Thematik Lebensmittelabfälle zu vermeiden schon länger bei mir präsent war hat das mit Foodsharing gut gepasst. ' +
			'Neben dem ökologischen Aspekt ist Foodsharing für mich aber vor allem auch ein soziales Projekt, denn es entwickelt sich eine schöne Gemeinschaft. ' +
			'Wenn ich Essen abhole und an Leute verteile entstehen automatisch Gespräche. ' +
			'Dadurch, dass ich einfach geklingelt habe lernte ich viele Leute aus meinem Haus kennen. ' +
			'Das gute alte Nachbarschaftsding eben.'
		]
	},
	{
		id: 21,
		linkTo: '/person/21',

		projectIds: [6],

		name: 'UMSONSTLADEN',
		attribute: 'mut',

		imgSrc: '/assets/img/people/person11-1.jpg',
		descriptions: [
			'Als ich dort was abgegeben habe bin ich mit einem der Helfer ins Gespräch gekommen. ' +
			'Er hat gefragt ob ich mir vorstellen könnte mitzumachen, es werden gerade Leute gesucht. ' +
			'Kurz darauf bin ich zu einem Orgatreffen gegangen und fand die Stimmung sehr entspannt. ' +
			'Alles wird so unhierarchisch und auf Augenhöhe organisiert. ' +
			'Das empfinde ich als angenehmen Gegenpol zu meiner alltäglichen, strukturierten Arbeit. ' +
			'Als Informationsdesigner bin ich gestaltend tätig. ' +
			'Ich finde die Idee schön, meine Fähigkeiten nicht nur bei Konzernen einzubringen, sondern auch bei ' +
			'gesellschaftlichen Gestaltungsprozessen mitzuwirken. ' +
			'Nicht nur der Kapitalakkumulation zu dienen, sondern auch die schönen Dinge im Leben zu fördern.'
		]
	},
	{
		id: 22,
		linkTo: '/person/22',

		projectIds: [6],

		name: 'UMSONSTLADEN',
		attribute: 'mut',

		imgSrc: '/assets/img/people/person11-2.jpg',
		descriptions: [
			'Ich finde es wunderbar, was für witzige und skurrile Menschen teilweise in den Umsonstladen kommen. ' +
			'Die Kundschaft ist ein bunter Mix an Menschen aus allen Gesellschaftsschichten, das ist wirklich sehr ' +
			'abwechslungsreich. ' +
			'Neulich ist ein Kunde einfach auf den Tisch geklettert und hat angefangen den Laden zu vermessen. ' +
			'Er hatte die Idee die Einrichtung komplett umzubauen. ' +
			'Letztlich war es nur eine Spinnerei, aber es war sehr witzig. ' +
			'So positiv.'
		]
	},
	{
		id: 23,
		linkTo: '/person/23',

		projectIds: [6],

		name: 'UMSONSTLADEN',
		attribute: 'mut',

		imgSrc: '/assets/img/people/person11-3.jpg',
		descriptions: [
			'Außerdem finde ich es gut, dass durch das Konzept die Nutzungsdauer von Dingen verlängert wird. ' +
			'Dass andere weiternutzen können was ich nicht mehr brauche. ' +
			'Dass man das lineare Wegwerfprinzip durch einen Zyklus des Weiternutzens ersetzt. ' +
			'Ich fände es gut, wenn es irgendwann in jedem Viertel so einen Laden gäbe. ' +
			'Damit es nicht nur an einer Stelle funktioniert und nicht nur an zwei Tagen die Woche. ' +
			'Damit diese Idee mehr Platz in der Gesellschaft einnimmt und wir wieder eine Tauschmentalität entwickeln.'
		]
	},
	{
		id: 24,
		linkTo: '/person/24',

		projectIds: [2],

		name: 'FREIRAUM',
		attribute: 'mut',

		imgSrc: '/assets/img/people/person12-1.jpg',
		descriptions: [
		    'Der Freiraum ist für mich ein sehr wichtiger Ort. Man kann sich reinsetzen, mit anderen Menschen sprechen, sich aufgehoben fühlen. Der Mensch braucht ja das Miteinander, das fehlt mir in unserer Gesellschaft oft. Ich denke wenn man dieses menschliche Grundbedürfnis erfüllt hat, braucht es diesen ganzen Konsum auch nicht mehr so. Eine so ausgeprägte Konsumfixierung ist meiner Meinung nach ein Ersatz für fehlende soziale Interaktionen.'
		]
	},
	{
		id: 25,
		linkTo: '/person/25',

		projectIds: [2],

		name: 'FREIRAUM',
		attribute: 'mut',

		imgSrc: '/assets/img/people/person12-2.jpg',
		descriptions: [
		    'Die Leute die sich engagieren haben wirklich großartige Ideen, doch in Würzburg rennst du teilweise gegen Windmühlen. Da kann man schon mutlos werden. Ich glaube viele spielen deshalb irgendwann mit dem Gedanken nach Leipzig oder Berlin zu gehen, einfach weil es dort mehr gibt was einen anspricht. Umso wichtiger ist es denke ich, dass es Projekte wie den Freiraum in Würzburg gibt. Und auch hier gibt´s Upcycling. Wir haben z.B. zwei alte Nähmaschinen mit denen wir aus Stoffresten noch etwas machen.'
		]
	},
	{
		id: 26,
		linkTo: '/person/26',

		projectIds: [5],

		name: 'KLEIDERTAUSCH',
		attribute: 'mut',

		imgSrc: '/assets/img/people/person13-1.jpg',
		descriptions: [
		    'Angefangen hat es mit dem offenen Treffen von Transition Town. Dort wurde schnell klar, dass einzelne Projekte noch Leute brauchen. Ich fand den Kleidertausch schon immer toll, aber die Initiatoren sind irgendwann weggezogen. Da dachte ich wunderbar, dann nehme ich das jetzt in die Hand und trage dazu bei, dass die Idee fortbesteht. Wir sind gerade drei Leute die fest dabei sind. Es helfen aber jedes Mal noch einige Leute mit.'
		]
	},
	{
		id: 27,
		linkTo: '/person/27',

		projectIds: [5],

		name: 'KLEIDERTAUSCH',
		attribute: 'mut',

		imgSrc: '/assets/img/people/person13-2.jpg',
		descriptions: [
			'Seit ich beim Kleidertausch mitmache ist mir bewusst welcher unglaubliche Überfluss eigentlich besteht. Das geht soweit, dass wir die übrig gebliebenen Klamotten fast nicht mehr losbekommen. Weil so viel übrig bleibt macht es Sinn den Kleidertausch öfter anzubieten. Dazu bräuchte es aber ein zweites Team, damit man sich abwechseln kann. Cool wäre es auch, wenn es jemanden gäbe der Lagermöglichkeiten für ein paar Säcke hätte, damit wir die Sachen bis zum nächsten Tausch aufbewahren könnten. Ansonsten hatten wir die Idee eine Installation aus den Überflussklamotten zu bauen. Also eigentlich ein Mahnmal. So nach dem Motto hey, da ist so viel was wir wegwerfen, überlegt euch doch das nächste Mal wenn ihr im Laden steht, ob ihr das wirklich braucht.'
		]
	},
	{
		id: 28,
		linkTo: '/person/28',

		projectIds: [5],

		name: 'KLEIDERTAUSCH',
		attribute: 'mut',

		imgSrc: '/assets/img/people/person13-3.jpg',
		descriptions: [
			'Der Umweltaspekt war für mich ausschlaggebend. Das Tauschen schont die Ressourcen, weil nichts neu produziert werden muss. Laut Statistiken tragen die Leute ihre gekauften Sachen im Schnitt nur sechs Mal bis sie weggeworfen werden. Der Tausch hat eigentlich nur Vorteile. Die Schadstoffe sind zum Beispiel schon ausgewaschen und du siehst teilweise wo deine Sachen hinkommen und wer sich darüber freut.'
		]
	},	
	{
		id: 29,
		linkTo: '/person/29',

		projectIds: [7],

		name: 'COMPUTERSPENDE',
		attribute: 'mut',

		imgSrc: '/assets/img/people/person14-1.jpg',
		descriptions: [
		    'Ich finde den Gedanken schön, dass die Initiativen sich vernetzen. Es ist immer wieder faszinierend, dass es hier so viele Menschen gibt, die sich einbringen. Gleichzeitig finde ich es krass, wie viele Leute noch gar nichts von den Projekten wissen. Aber bist du erst einmal in diese Welt eingetaucht, so wie ich bei Angestöpselt, dann siehst du wie viel es in Würzburg gibt. Das ist echt gut.'
		]
	},
	{
		id: 30,
		linkTo: '/person/30',

		projectIds: [7],

		name: 'COMPUTERSPENDE',
		attribute: 'mut',

		imgSrc: '/assets/img/people/person14-2.jpg',
		descriptions: [
		    'Bei den Mitwirkenden solltest du immer im Kopf haben, dass sie da sind weil sie Bock drauf haben und das freiwillig machen. Dadurch finden ganz andere Abstimmungsprozesse statt, die eben auf Konsens basieren. Es soll ja jeder maximalmotiviert dabei sein. Die große Herausforderung ist es, dass einerseits Mitwirkende benötigt werden, andererseits aber ja kein Auswahlprozess stattfindet, wie bei Arbeitsstellen. Es wird eben mit denen gearbeitet, die kommen und Lust darauf haben. Entsprechend sind bei uns nur ein paar wirkliche IT-Spezialisten. Die meisten sind Nicht-IT-ler, die bei uns mitmachen, um auch für sich etwas zu lernen.'
		]
	},
	{
		id: 31,
		linkTo: '/person/31',

		projectIds: [7],

		name: 'COMPUTERSPENDE',
		attribute: 'mut',

		imgSrc: '/assets/img/people/person14-3.jpg',
		descriptions: [
		    'Der Grundgedanke war eigentlich relativ banal: Ich wollte einfach etwas Sinnvolles tun. Nicht, dass ich meine Arbeit nicht als sinnstiftend erachten würde, aber ich meine im sozialen Bereich. Das Geschäftsleben ist eben auf´s Geld verdienen ausgerichtet, ich wollte aber noch etwas für´s Herz. Was Gutes tun. Mich daran erfreuen jemand anderem etwas zu geben. Raus aus diesem kommerziellen Konstrukt, dass alles nur über Geld geht. Ich finde schenken und tauschen als gesellschaftliches Alternativmodell zu dem Tauschmittel Geld sehr spannend.'
		]
	},
	{
		id: 32,
		linkTo: '/person/32',

		projectIds: [4],

		name: 'URBAN GARDENING',
		attribute: 'mut',

		imgSrc: '/assets/img/people/person15-1.jpg',
		descriptions: [
		    'Wir haben schon viel positive Resonanz auf unsere Hochbeete bekommen. Leute, die an den Beeten vorbeilaufen, interessieren sich für das was wir dort anbauen. Wir haben z.B. verschiedene Tomatensorten, nicht nur rot, rund und mittelgroß, sondern von grün über orange-rot bis hin zu schwarz-gestreift ist alles dabei. Damit die Hummeln und die Bienen nicht zu kurz kommen haben wir eine bienenfreundliche Blumenmischung, die wir da mit reinstreuen. So kommt zusätzlich Farbe ins Beet. Heute haben viele gezüchteten Blumen gar keinen Pollen oder Nektar mehr. Wir achten deshalb darauf, dass die Insekten einen Mehrwert davon haben.'
		]
	},
	{
		id: 33,
		linkTo: '/person/33',

		projectIds: [4],

		name: 'URBAN GARDENING',
		attribute: 'mut',

		imgSrc: '/assets/img/people/person15-2.jpg',
		descriptions: [
		    'Wir vermehren unser Saatgut z.T. selbst, um es im Folgejahr wieder auszusähen. Das besondere an unseren Pflanzen ist, dass sie samenfest sind. Das heißt, dass genau diese Pflanze wieder herauskommt, wenn man die Samen aussät. Bei den konventionellen Hybridsorten ist das nicht so, weil sie eine Kreuzung aus zwei verschiedenen Sorten sind. Sie werden verwendet um beispielsweise einen höheren Ertrag zu erzielen. Das ist allerdings nur ein kurzfristiger Vorteil, denn dadurch macht sich der Verbraucher von der Saatgut-Industrie abhängig. Er muss jedes Jahr wieder neues Saatgut einkaufen. Außerdem haben die Pflanzen durch die ständigen Kreuzungen nur noch einen sehr schmalen Genpool. Wenn sich die klimatischen Bedingungen verändern, sind konventionelle Pflanzen viel krankheitsanfälliger und müssten, um hohe Erträge zu gewährleisten, durch die Verwendung von noch mehr Pestiziden geschützt werden."'
		]
	},
	{
		id: 34,
		linkTo: '/person/34',

		projectIds: [4],

		name: 'URBAN GARDENING',
		attribute: 'mut',

		imgSrc: '/assets/img/people/person15-3.jpg',
		descriptions: [
		    'Wir verwenden nur historische Gemüsesorten, weil sie es Wert sind wieder vermehrt zu werden. Damit wollen wir den Menschen zeigen, dass es mehr gibt als den Supermarktstandard. Wir möchten zeigen, dass Farbe, Größe und Form variieren können und damit das Interesse für dieses Thema wecken. Viele alte Sorten sind in Vergessenheit geraten, weil sie nach heutigen Standards als unwirtschaftlich eingestuft werden. Sie erzeugen häufiger weniger Erträge, brauchen länger um zu reifen, haben Form-und Größenunterschiede und sind dadurch nicht verpackungskonform. Der Handel braucht für die maschinelle Verpackung Früchte mit dem gleichem Gewicht und Größe - sonst lohnt sich das für die Großkonzerne nicht.'
		]
	},
	{
		id: 35,
		linkTo: '/person/35',

		projectIds: [0],

		name: 'REPAIR CAFE',
		attribute: 'mut',

		imgSrc: '/assets/img/people/person16-1.jpg',
		descriptions: [
		    'Ich gebe meistens Kaffee und Kuchen gegen Spende aus. Jeder gibt was er möchte, aber das ist natürlich kein Zwang. Wenn jemand gerade nichts geben kann ist das doch auch ok. Ich finde es faszinierend wie verblüfft die Leute sind, wenn sie erfahren, dass alles auf freiwilliger Basis passiert. Ist ja klar, eine Spende die man geben muss ist nicht mehr freiwillig. Das ist ein unglaublich befriedigendes Gefühl, hinter der Theke zu stehen und die Ware einfach kostenlos rauszugeben. Dadurch, dass die Kuchen mitgebracht werden haben wir auch keine Unkosten. Von dem Erlös kaufen wir Material und Geschenke für die Reparaturexperten.'
		]
	},
	{
		id: 36,
		linkTo: '/person/36',

		projectIds: [0],

		name: 'REPAIR CAFE',
		attribute: 'mut',

		imgSrc: '/assets/img/people/person16-2.jpg',
		descriptions: [
		    'Die Kaffee-Ecke ist eine ideale Austauschplattform. Gespräche fangen meist damit an, wer was reparieren ließ. Dann geht es häufig weiter mit den Beweggründen, warum die Leute da sind. Viele kommen weil sie kein Geld für kostenpflichtige Reparaturen haben und sich auch kein neues Teil leisten können. Diese Menschen treffen beim Repair Café dann auf Leute, die da sind weil sie die Ressourcen schonen und die Nutzungsdauer ihrer Gegenstände verlängern möchten. Wenn diese Menschen sich austauschen ist das eine ganz tolle Sache, auch weil sich so Gesellschaftsschichten mit unterschiedlichen sozialen Hintergründen mischen. Da gibt es keine Wertigkeit. Ich denke auf dieser Ebene kann man am besten voneinander lernen.'
		]
	},
	{
		id: 37,
		linkTo: '/person/37',

		projectIds: [8],

		name: 'FABLAB',
		attribute: 'mut',

		imgSrc: '/assets/img/people/person17-1.jpg',
		descriptions: [
		    'Am Anfang hatten wir keine eigenen Räume, die wir aber brauchten, um an größeren Projekten zu arbeiten. Dann haben wir festgestellt, dass es noch andere Leute gibt, die ähnliches vorhatten. Die waren nur zu zweit und hatten zwar große Maschinen, wie Standbohrer und den 3D-Drucker, aber nicht genug Leute, um einen Verein zu gründen. Wir haben uns einfach mal zusammengesetzt und darüber geredet, was wir so gemeinsam tun können. So ist der Verein Nerd2Nerd entstanden und daraus wieder das Fablab, das ich 2014 mitgegründet habe.'
		]
	},
	{
		id: 38,
		linkTo: '/person/38',

		projectIds: [8],

		name: 'FABLAB',
		attribute: 'mut',

		imgSrc: '/assets/img/people/person17-2.jpg',
		descriptions: [
		    'Wir interessieren uns schon lange für Themen, die zunehmend in das Bewusstsein einer breiteren Masse rücken, auch weil sie vermehrt in den Medien vorkommen: Digitalisierung, Datenschutz, Privatsphäre und Computersicherheit. Wir möchten verstehen wie etwas funktioniert, wie man es sich zunutze machen und verbessern kann. Das ist ja eigentlich ein Grundprinzip der Menschheit; das ist etwas das den Menschen ausmacht. Mit dem Fablab möchten wir genau das fördern.'
		]
	},
	{
		id: 39,
		linkTo: '/person/39',

		projectIds: [6],

		name: 'UMSONSTLADEN',
		attribute: 'mut',

		imgSrc: '/assets/img/people/person18-1.jpg',
		descriptions: [
		    'Ich hatte so viel überflüssiges Zeug zuhause und habe nach einer Möglichkeit gesucht es sinnvoll weiterzugeben. Als ich im Radio einen Beitrag über den Umsonstladen gehört habe dachte ich mir: Cool, endlich gibt es hier so etwas. Bei meinem ersten Besuch war der Laden noch klein und mit der Zeit wurde er immer größer. Eine schöne Entwicklung. Obwohl ich die Idee auf Anhieb super fand, hat es etwas gedauert, bis ich auch wirklich nachgefragt habe, ob ich dort mithelfen kann. Seitdem lebe ich auch selbst den Minimalismusgedanken. Ich überlege mir genau was ich brauche und einkaufe und fühle mich dadurch sehr viel freier. Ich weiß, dass ich bei einem Umzug schnell zusammenpacken könnte und nicht währenddessen noch den Stress hätte Sachen loszuwerden. Ach ja, der Schal ist übrigens aus dem U-Laden.'
		]
	},
	{
		id: 40,
		linkTo: '/person/40',

		projectIds: [1],

		name: 'FOODSHARING',
		attribute: 'mut',

		imgSrc: '/assets/img/people/person19-1.jpg',
		descriptions: [
		    'Es ist echt schön zu sehen, dass das Projekt wächst und bekannter wird. Ich bin seit über zwei Jahren dabei. Vor ein paar Jahren habe ich schon mal auf die Webseite von foodsharing geschaut und dachte mir: Coole Idee. Aber als ich dann gesehen habe, dass es in Würzburg noch nichts gibt war das erst mal enttäuschend. Jahre später war dann meine Freundin bei foodsharing angemeldet. Sie hat mich über den spielerischen Ansatz dazu gekriegt mich wieder dafür zu interessieren. Da gab es dieses Quiz, das foodsharing eingeführt hat. Sie meinte wetten, dass du dieses Quiz nicht schaffst. Und ich meinte na klar, wetten doch. Ich dachte ich weiß eben schon einiges, was es zu dem Thema gibt und bin durch den Test gefallen, haha. Da habe ich gemerkt, dass es echt noch so viel interessantes Hintergrundwissen gibt.'
		]
	},
	{
		id: 41,
		linkTo: '/person/41',

		projectIds: [1],

		name: 'FOODSHARING',
		attribute: 'mut',

		imgSrc: '/assets/img/people/person19-2.jpg',
		descriptions: [
		    'Mir gefällt an foodsharing, dass es durch alle Gesellschaftsschichten und Altersstufen geht. Wir haben von Jugendlichen bis Rentnern alle dabei. Sie arbeiten einfach mal zusammen an einer Sache, weil sie die Idee von foodsharing gut finden. Das wichtigste Ziel ist es die Lebensmittelverschwendung zu minimieren. Vor allem im praktischen Sinn, indem wir Lebensmittel davor bewahren weggeworfen zu werden. Es gibt eine politische Kampagne die zwar nicht von foodsharing ist, aber sich mit uns überschneidet. Das Projekt heißt Leere Tonne und strebt vor allem politische Veränderungen an. Aber im Endeffekt betreibe ich bei foodsharing ja auch Politik. Ich erzähle es weiter und die Leute finden es in der Regel gut, interessant und unterstützenswert. Das bringt auch schon sehr viel.'
		]
	},
	{
		id: 42,
		linkTo: '/person/42',

		projectIds: [2],

		name: 'FREIRAUM',
		attribute: 'mut',

		imgSrc: '/assets/img/people/person20-1.jpg',
		descriptions: [
			'Im Freiraum können sich alle mit ihren Ideen einbringen und ihn nach ihren Wünschen mitgestalten. Dahinter steht die Philosophie, dass alles geteilt wird und jeder unabhängig von Status oder Geld einfach da sein kann. Man darf natürlich auch einfach nichts tun. Es ist völlig ok, den ganzen Tag nur Tee zu trinken und die Tasse am Ende des Tages dann nicht zu spülen. Irgendwann kommen die Leute meistens von selbst auf die Idee sich einzubringen. Eine Frau war z.B. immer unsicher, ob sie überhaupt da sein darf, weil sie kein Geld hatte für unsere Spendenbox. Irgendwann hat sie dann angefangen selbstgebackenes Brot mitzubringen und sich unglaublich gefreut, wenn es andere gegessen haben. Ich glaube, dass es Menschen einfach glücklich macht etwas zu geben.'
		]
	},
	{
		id: 43,
		linkTo: '/person/43',

		projectIds: [2],

		name: 'FREIRAUM',
		attribute: 'mut',

		imgSrc: '/assets/img/people/person20-2.jpg',
		descriptions: [
			'Ich war dort als Besucherin und habe angeboten mitzuhelfen, wenn noch Leute gebraucht werden. Da das eigentlich immer der Fall ist, habe ich schon am nächsten Tag für ne Soliparty gekocht. Es hat sich so schön angefühlt, sich gebraucht und aufgehoben zu fühlen. In nächster Zeit werde ich deshalb die Fühler weiter ausstrecken, um mehr Leute zu finden, die den Raum mitgestalten und die Utopie dahinter mit tragen möchten. Da die Chancen am größten sind, wenn man jemanden direkt anspricht: fühl dich hiermit ganz herzlich angesprochen! Komm doch einfach mal bei uns vorbei.'
		]
	},
	{
		id: 44,
		linkTo: '/person/44',

		projectIds: [2],

		name: 'FREIRAUM',
		attribute: 'mut',

		imgSrc: '/assets/img/people/person20-3.jpg',
		descriptions: [
			'Oft ist das Miteinander ja eine Frage von Schwellen. Je nachdem wo etwas stattfindet kommen bestimmte Menschen, die zu einer ähnlichen Gesellschaftsschicht oder Altersgruppe gehören. Im Freiraum ist es anders. Hier kommen Kinder, ebenso wie Senioren und alle Altersgruppen dazwischen. Ich denke nur so lernt man wirklich sich mit anderen Menschen zu arrangieren, ihre Bedürfnisse zu sehen und zu verstehen. Wir fragen uns deshalb immer, wie wir einen Raum gestalten können, in dem jeder auf seine Bedürfnisse kommt. Das ist natürlich ein Prozess der unendlich ist. Aber ich merke, wie ich seitdem sehr viel geduldiger mit anderen Menschen geworden bin und ihre Macken auch mal akzeptieren kann und sie trotz oder wegen ihrer Macken gern hab.'
		]
	}
];