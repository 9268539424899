var React = require('react'),
	PropTypes = require('react').PropTypes,

	Link = require('react-router').Link;

var Footer = React.createClass({

	propTypes: {
		copyright: PropTypes.string,
		security: PropTypes.shape({
			value: PropTypes.string.isRequired,
			to: PropTypes.string.isRequired
		}).isRequired,
		small: PropTypes.bool
	},

	render: function(){
		
		var fClassName = 'footer';
		fClassName += this.props.small === true ? ' small' : '';

		return(
			<footer className={fClassName}>
				{! this.props.small ?
					<div>
						<div className="text copyright">{this.props.copyright}</div>
						<Link className="text security" to={this.props.security.to}>{this.props.security.value}</Link>
						<Link className="text impressum" to="/impressum">Impressum</Link>
						<Link className="text contributors" to={this.props.contributors.to}>{this.props.contributors.value}</Link>
					</div>
				: null }

			</footer>
		)
	}

});

module.exports = Footer;