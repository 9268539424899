var React = require('react'),

	data = require('../data/data').projectOverview(),

	Link = require('react-router').Link,
	browserHistory = require('react-router').browserHistory,

	Page = require('./page/page.jsx'),
	Headline = require('./onpage/headline/headline.jsx'),
	Content = require('./onpage/content/contentText.jsx'),
	ProjectTeaser = require('./onpage/projectTeaser/projectTeaser.jsx'),
	Slider = require('./onpage/slider/slider.jsx');


var Project = React.createClass({

	handleClick: function(dir) {
		var projectId = this.state.projectId;
		dir === 'left' ? this.previous(projectId) : this.next(projectId);
	},

	next: function (current) {
		var projectId = current < data.projects.length-1 ? ++current : 0;
		this.setState({projectId: projectId});
		browserHistory.push('/project/' + projectId);
	},

	previous: function (current) {
		var projectId = current > 0 ? --current : data.projects.length-1;
		this.setState({projectId: projectId});
		browserHistory.push('/project/' + projectId);
	},

	getInitialState: function () {
		return {
			projectId: this.props.params.pid
		};
	},


	nextProjectId: function (current) {
		return current > 0 ? --current : data.projects.length-1;
	},

	previousProjectId: function (current) {
		return current < data.projects.length-1 ? ++current : 0;
	},

	render: function () {

		var currProj = data.projects[this.state.projectId];

		return(
			<Page
				header={data.header}
				footer={data.footer}
			>

			<div className="project">
				<Headline title="">
					<Link to="/projects">Alle Projekte</Link>
				</Headline>


				<Slider
					onClickLeft={this.handleClick.bind(this, 'left')}
					onClickRight={this.handleClick.bind(this, 'right')}>
					<ProjectTeaser
						teaserImgSrc={currProj.teaserImgSrc}
						projectName={currProj.projectName}
						projectDescription={currProj.projectDescription}
						linkTo={currProj.linkTo}
						disableLink={true}
					>
					</ProjectTeaser>
				</Slider>

				<div className="">

					<div className="project-info-container">

						<div className="project-info">
							<Content headline={currProj.details[0].title}>
								{currProj.details[0].value}
							</Content>
						</div>

						<div className="project-add-info-outer">

							<div className="infobox" name="meeting-info">

								<a href={'mailto:'+currProj.contact.email} target="_blank" className="mail">

								</a>
								<a href={currProj.contact.site} target="_blank" className="site">

								</a>
								<a href={'https://' + currProj.contact.facebook} target="_blank">

								</a>
								<div className="normal">
									{currProj.contact.meetings.map(function (meeting) {
										return(
											<div key={meeting.title}>
												<div className="bold title">{meeting.title}</div>
												{meeting.value.map(function (val) {
													return(
														<div key={val} className="normal">{val}</div>
													)
												})}
											</div>
										)
									})}
								</div>
								<div>{currProj.contact.location.join(', ')}</div>
							</div>
						</div>
					</div>


					<div className="project-info-container">

						<div className="project-info">
							<Content headline={currProj.details[1].title}>
								{currProj.details[1].value}
							</Content>
						</div>

						<div className="project-add-info-outer" name="donate-info">

							{(function () {
								if(currProj.donate){
									return(
										<div className="infobox">
											<div className="bold title">{currProj.donate.title}</div>
											<div className="normal owner">{currProj.owner}</div>
											<div className="bold">IBAN</div><div className="normal">{currProj.donate.iban}</div>
											<div className="bold">BIC</div><div className="normal">{currProj.donate.bic}</div>
											<div>{currProj.donate.bank}</div>
										</div>
									)
								}
							}).bind(this)()}

						</div>
					</div>
				</div>
			</div>
			</Page>

		)
	}
	
});

module.exports = Project;