var React = require('react'),
	Link = require('react-router').Link;

var facebook = React.createClass({

	/**
	 * Initially, the class names were social social-facebook
	 * Adblock removed these from the page due to filtering options
	 * Changed the class names to more weird ones to disable filtering
	 *
	 * Done on 21.04.2016, last checked for working: 21.04.2016
	 */

	propTypes: {
		//children: React.PropTypes.element.isRequired,
		fLink: React.PropTypes.string.isRequired,
		to: React.PropTypes.string.isRequired
	},

	render: function () {
		return (
			<div className="ntwrk ntwrk-fbck" to={this.props.to}>
				<div className="ntwrk-wrapper">
					<div className="text">{this.props.children}</div>
					<a className="fbckBtn smGlobalBtn" href="https://facebook.com/wandelmut" target="_base"></a>
				</div>
			</div>
		)
	}

});

module.exports = facebook;