module.exports = {

	location: 'Würzburg',

	menu: {
		collapsed: true,
		links: [
			{
				to: '/?onsite=true',
				value: 'Start'
			},
			{
				to: '/projects',
				value: 'Projekte'
			},
			{
				to: '/participate',
				value: 'Mitmachen'
			},
			{
				to: '/financial',
				value: 'Spenden'
			},
			{
				to: '/people',
				value: 'Menschen'
			},
			{
				to: '/info',
				value: 'Hintergrund'
			}
		]
	}

};