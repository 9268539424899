var React = require('react'),
	ReactDOM = require('react-dom'),

	mountNode = document.getElementById('react-main-mount'),

	History = require('react-router').browserHistory,
	Router = require('react-router').Router,

	routes = require('./routes/react-routes.jsx');



/* client side rendering */
ReactDOM.render(<Router onUpdate={updateScrollPosition} history={History} children={routes} />, mountNode);


/**
 * This function is necessary to ensure correct scrolling behavior.
 *
 * In case any anchor links are clicked (to scroll to an element on the same page)
 * the window will scroll to the correct position by default browser behavior.
 *
 * If no anchor link is clicked, the function will ensure scrolling to
 * the top of the page.
 */
function updateScrollPosition() {

	window.location.hash = window.decodeURIComponent(window.location.hash);
	var hashParts = window.location.hash.split('#');

	if(hashParts.length < 2) {
		window.scrollTo(0, 0);
	}

}