var React = require('react'),
	Link = require('react-router').Link,

	data = require('../data/data').peopleOverview(),
	projects = require('../data/data').projectOverview().projects,

	Page = require('./page/page.jsx'),
	Headline = require('./onpage/headline/headline.jsx'),
	Filter = require('./onpage/headline/filter.jsx'),
	Masonry = require('../components/onpage/grid/masonry.jsx');



var PeopleOverview = React.createClass({

	onFilterChange: function (targetId) {

		var activeValues = projects.filter(function (project) {
			return (project.id == targetId)
		});

		var otherValues = projects.filter(function (project) {
			return (project.id != targetId)
		});

		otherValues = otherValues.map(function (project) {
			return {
				id: project.id,
				value: project.projectName
			}
		});

		var activeValue;

		if(activeValues.length === 0) {
			activeValue = this.getDefaultFilter();
		} else {
			otherValues.push(this.getDefaultFilter());
			activeValue = {
				id: activeValues[0].id,
				value: activeValues[0].projectName
			};
		}



		this.setState({
			activeValue: activeValue,
			otherValues: otherValues
		});
	},

	getFilteredPeople: function (state) {
		return data.people.filter(function (person) {
			return (state.activeValue.id === -1 || person.projectIds.indexOf(state.activeValue.id) >= 0);
		});
	},

	getDefaultFilter: function () {
		return {
			id: -1,
			value: 'Alle Portraits'
		}
	},

	getInitialState: function () {
		return {
			activeValue: this.getDefaultFilter(),
			otherValues: projects.map(function(project){
				return {
					id: project.id,
					value: project.projectName
				}
			})
		}
		
	},
	
	render: function(){

		return(
			<Page
				header={data.header}
				footer={data.footer}
			>
				<div className="people-headline">
					<Headline title="">
						<Filter
							activeValue={this.state.activeValue}
							otherValues={this.state.otherValues}
							onFilterChange={this.onFilterChange}
						/>
					</Headline>
				</div>

				<div className="people-overview">
					<Masonry>
						{this.getFilteredPeople(this.state).map(function (person) {
							return(
								<Link to={person.linkTo} className="person-teaser" key={person.id}>
									<div
										className="people-element"
									>
										<img src={person.imgSrc} />
										<div className="person-image-hover"></div>
									</div>
								</Link>
							)
						})}
					</Masonry>
				</div>

			</Page>
		)
	}

});

module.exports = PeopleOverview;


//style={{backgroundImage: 'url(' + person.imgSrc +')'}}
//style={{visibility: 'hidden'}}


