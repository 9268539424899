var Route = require('react-router').Route,
	IndexRoute = require('react-router').IndexRoute,

	// we need to require React here or it won't be available
	// on the server side
	React = require('react'),

	ReactApp = require('../components/_app.jsx'),
	LandingPage = require('../components/landingPage.jsx'),
	ProjectOverview = require('../components/projectOverview.jsx'),
	Project = require('../components/project.jsx'),
	PeopleOverview = require('../components/peopleOverview.jsx'),
	Person = require('../components/person.jsx'),
	Participate = require('../components/participate.jsx'),
	Financial = require('../components/financial.jsx'),
	Info = require('../components/info.jsx'),
	Impressum = require('../components/impressum.jsx'),
	DataSecurity = require('../components/dataSecurity.jsx'),
	Contributors = require('../components/contributors.jsx');



/**
 * This module solely consists of route definitions.
 * The component "ReactApp" is always loaded as the applications frame.
 * Each child-route is rendered into this component.
 *
 * Add additional top-level routes for new frames.
 *
 * The IndexRoute indicates the route to be loaded when no other is specified.
 */
module.exports = (
	<Route path="/" component={ ReactApp }>
		<IndexRoute component={ LandingPage } />
		<Route path="projects" component={ ProjectOverview }/>
		<Route path="project/:pid" component={ Project } />
		<Route path="people" component={ PeopleOverview } />
		<Route path="person/:pid" component={ Person } />
		<Route path="participate" component={ Participate } />
		<Route path="financial" component={ Financial } />
		<Route path="info" component={ Info } />
		<Route path="impressum" component={ Impressum } />
		<Route path="datasecurity" component={ DataSecurity } />
		<Route path="contributors" component={ Contributors } />
	</Route>
);