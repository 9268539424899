var React = require('react');

var Headline = React.createClass({

	propTypes: {
		title: React.PropTypes.string,
		children: React.PropTypes.element,
		fading: React.PropTypes.bool
	},

	defaultProps: {
		fading: false
	},

	render: function () {

		var headlineClassName = 'fading ';
		headlineClassName += this.props.fading ? 'active' : 'inactive';

		return (
			<div className="headline">
				<div className={headlineClassName}>
					<div className="title">{this.props.title}</div>
				</div>
					<div className="filter">{this.props.children}</div>
			</div>
		)
	}

});

module.exports = Headline;