var React = require('react');

var Slider = React.createClass({

	propTypes: {
		children: React.PropTypes.element.isRequired,
		onClickLeft: React.PropTypes.func.isRequired,
		onClickRight: React.PropTypes.func.isRequired
	},

	render: function(){

		// TODO implement slider with <a> instead of js-transition for non-js support

		return(
			<div className="slider">
				<div className="btn left" onClick={this.props.onClickLeft}>
					<div className="icon">W</div>
				</div>
				<div className="content">
					{this.props.children}
				</div>
				<div className="btn right" onClick={this.props.onClickRight}>
					<div className="icon">W</div>
				</div>
				<div className="slider-hover">
				</div>
			</div>
		)
	}

});

module.exports = Slider;