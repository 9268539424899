var React = require('react'),
	key = 0;

// TODO use different grid key to prevent server-client-break when reloading page with grid directly

var Masonry = React.createClass({


	shuffle: function (a) {
		var j, x, i;
		for (i = a.length; i; i -= 1) {
			j = Math.floor(Math.random() * i);
			x = a[i - 1];
			a[i - 1] = a[j];
			a[j] = x;
		}
	},

	/**
	 * Pass an array of children 1-n that will be rendered as masonry elements
	 * Whatever is used as children will be rendered as they are
	 */

	render: function () {


		var columnLeft = [], columnMiddle = [], columnRight = [],
			index = 0,

			people = React.Children.toArray(this.props.children);

		// TODO this assumes that the newest entry is always at the bottom
		people.reverse();

		people.forEach(function (child) {
			if(index % 3 == 0) {
				columnLeft.push(child);
			}
			if(index % 3 == 1) {
				columnMiddle.push(child);
			}
			if(index % 3 == 2) {
				columnRight.push(child);
			}

			index++;
		});


		return (
			<div className="masonry-layout">

				<div className="masonry">

					<div className="masonry-column">
						{columnLeft.map(function (child) {
							return(
								<div key={'masonry-el-' + key++} className="masonry-item">
									{child}
								</div>
							)
						})}
					</div>

					<div className="masonry-column">
						{columnMiddle.map(function (child) {
							return(
								<div key={'masonry-el-' + key++} className="masonry-item">
									{child}
								</div>
							)
						})}
					</div>

					<div className="masonry-column">
						{columnRight.map(function (child) {
							return(
								<div key={'masonry-el-' + key++} className="masonry-item">
									{child}
								</div>
							)
						})}
					</div>

				</div>

			</div>
		)
	}

});

module.exports = Masonry;