module.exports = {

	header: require('./header'),

	teasers: [
		{
			value: 'Sinnvolle Projekte brauchen Menschen die mitmachen.'
		},
		{
			value: 'Die Auswahlkriterien helfen das Richtige zu finden.'
		}
	],

	questions: [
		{
			value: 'WAS IST MIR WICHTIG?',
			targetAttrType: 1
		}
	],

	footer: require('./footer')

};