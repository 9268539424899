var React = require('react'),

	data = require('../data/data').landingPage(),

	Page = require('./page/page.jsx'),
	ContentText = require('./onpage/content/contentText.jsx');



var Contributors = React.createClass({

	render: function(){

		return(
			<Page
				header={data.header}
				footer={data.footer}
			>
				<div className="impressum-content">

					<br/><br/>

					<p>Konzeption, Gestaltung, Text und Fotografie: <a target="_blank" href="http://www.kathrinkoenigl.de">Kathrin Königl</a></p>
					<p>Programmierung I: <a target="_blank" href="http://www.toni-fetzer.de">Toni Fetzer</a> und Patrick Mennig</p>
					<p>Programmierung II: <a target="_blank" href="http://www.systhemis.de">SYSTHEMIS AG</a></p>
					<a target="_blank" href="http://www.systhemis.de"><img src="../assets/img/systhemis.png" height="40px" width="auto" style={{position: 'absolute', marginTop: -48, marginLeft: 260}}/></a>
					<p>Illustration: <a target="_blank" href="http://janiksoellner.tumblr.com">Janik Soellner</a></p>
					<p style={{display: 'inline'}}>Webserver: <a target="_blank" href="http://www.sonith.de">Sonith UG</a></p>
					<a target="_blank" href="http://www.sonith.de"><img src="../assets/img/sonith.png" height="40px" width="auto" style={{position: 'absolute', marginTop: -12, marginLeft: 10}}/></a>
					<br/><br/>
					<p style={{display: 'inline'}}>Druckförderung Postkarten: <a target="_blank" href="http://www.umweltstiftung-wuerzburg.de/">Würzburger Umwelt- und Naturstiftung</a></p>
					<a target="_blank" href="http://www.umweltstiftung-wuerzburg.de/"><img src="../assets/img/WUNSt-logo.jpg" height="40px" width="auto" style={{position: 'absolute', marginTop: -20, marginLeft: 10}}/></a>
				</div>
			</Page>
		)
	}

});

module.exports = Contributors;


