var React = require('react');

var ContentParallel = React.createClass({

	/* expecting two children of contentText type */

	render: function () {
		return (
			<div className="content-parallel">
				{this.props.children}
			</div>
		)
	}

});

module.exports = ContentParallel;