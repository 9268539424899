var React = require('react'),
	Link = require('react-router').Link,

	data = require('../data/data').projectOverview(),

	Page = require('./page/page.jsx'),
	Grid = require('./onpage/grid/grid.jsx'),
	ProjectTeaser = require('./onpage/projectTeaser/projectTeaser.jsx');



var ProjectOverview = React.createClass({

	// TODO refactor/extract table into seperate class



	getInitialState: function () {

		function shuffle(array) {
			var counter = array.length;

			// While there are elements in the array
			while (counter > 0) {

				// Pick a random index
				var index = Math.floor(Math.random() * counter);

				// Decrease counter by 1
				counter--;

				// And swap the last element with it
				var temp = array[counter];
				array[counter] = array[index];
				array[index] = temp;
			}

			return array;
		}

		var projects = [];
		data.projects.forEach(function (p) {
			projects.push(p);
		});


		var projectsList = [];
		data.projects.forEach(function (p) {
			projectsList.push(p);
		});

		projectsList.sort(function (a, b) {
			return a.projectName < b.projectName ? -1 : 1;
		});

		return {
			projects: shuffle(projects),
			projectlist: projectsList
		}
	},

	render: function(){

		return(
			<Page
				header={data.header}
				footer={data.footer}
			>

				<Grid>
					{this.state.projects.map(function (project) {
						return(
							<ProjectTeaser
								key={'grid-el'+project.id}
								teaserImgSrc={project.teaserImgSrc}
								projectName={project.projectName}
								projectDescription={project.projectDescription}
								linkTo={project.linkTo}>
							</ProjectTeaser>
						)
					})}
				</Grid>


				<div className="project-table">
					<div className="bar"></div>
					{this.state.projectlist.map(function (project) {
						return(
							<div
								className="row"
								key={'row-'+project.id}
							>
								<Link to={'/project/' + project.id} className="row-element title">{project.projectName}</Link>
								<Link to={'/project/' + project.id} className="row-element owner">{project.owner}</Link>
								<a href={'mailto:' + project.contact.email} className="row-element mail">
									<img src="" />
								</a>
								<a href={project.contact.site} target="_blank" className="row-element site">
									<img src="" />
								</a>
							</div>
						)
					})}
				</div>

			</Page>
		)
	}

});

module.exports = ProjectOverview;


