var React = require('react'),
	PropTypes = require('react').PropTypes;



var MultiFilter = React.createClass({

	propTypes: {

		question: PropTypes.string.isRequired,
		values: PropTypes.arrayOf(PropTypes.shape({
			id: PropTypes.number.isRequired,
			value: PropTypes.string.isRequired,
			selected: PropTypes.bool.isRequired,
			count: PropTypes.number.isRequired
		})),

		onFilterChange: PropTypes.func.isRequired,
		onDeselectAll: PropTypes.func.isRequired,

		expanded: PropTypes.bool,
		extraClassName: PropTypes.string
	},

	defaultProps: {
		expanded: false
	},

	getInitialState: function () {
		return {
			expanded: this.props.expanded
		}
	},

	onSelectClick: function () {
		this.setState({expanded: !this.state.expanded});
	},

	render: function () {

		var outerClassName = 'multi-filter ' + this.props.extraClassName,
			optionsClassName = 'options ';
		optionsClassName += this.state.expanded ? 'expanded' : 'collapsed';

		return (

			<div className={outerClassName}>

				<div className="select" onClick={this.onSelectClick}>

					{(function (self) {
						return self.state.expanded ? (<div className="icon expanded">W</div>) : (<div className="icon collapsed">W</div>)
					})(this)}

					<div className="question">{this.props.question}</div>

				</div>

				<div className={optionsClassName}>

					{this.props.values.map(function(value) {
						if (value.count == 0) {
							return(
								<div className="option" key={value.id}>
									<label>
										<input type="checkbox" value={value.id} checked={value.selected} onChange={this.props.onFilterChange} disabled readOnly />
										{value.value} ({value.count})
									</label>
								</div>
							)
						}
						else {											
							return(
								<div className="option" key={value.id}>
									<label>
										<input type="checkbox" value={value.id} checked={value.selected} onChange={this.props.onFilterChange} />
										{value.value} ({value.count})
									</label>
								</div>
							)
						}
					}, this)}

					<div className="reset" onClick={this.props.onDeselectAll}>
						<div className="icon"></div>
						<div className="hint">Filter aufheben</div>
					</div>
				</div>

			</div>
		)
	}
});

module.exports = MultiFilter;





/*
 {(function optionsIfExpanded (self) {
 if(self.state.expanded){
 return(
 <div className="options expanded">
 {self.props.values.map(function(value) {
 return(
 <div className="option" key={value.id}>
 <label>
 <input type="checkbox" value={value.id} checked={value.selected} onChange={this.props.onFilterChange} />
 {value.value}
 </label>
 </div>
 )
 }, self)}
 </div>
 )
 }

 return(<div className="options collapsed"/>)
 })(this)}
 */